import React, { FC, useEffect, useState, useCallback } from 'react';
import { Col, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import SubmitWithLoading from "../../../layouts/UI/SubmitWithLoading";
import userDummayImage from "../../../../assets/images/users/user-dummy-img.jpg";
import TitlePortfolioPage from "../portfolioPage/TitlePortfolioPage";
import { useAppDispatch } from "../../../../hooks/redux";
import {setIsSend} from "../../../../store/reducers/widgets/WidgetsSlice";

interface IAddTitlePage {
    portfolio: any,
    setPortfolio(value: any): any,
    selectEditPagePortfolio: boolean,
    setSelectEditPagePortfolio(value: boolean): any
}

const EditTitlePage: FC<IAddTitlePage> = ({ portfolio, selectEditPagePortfolio, setSelectEditPagePortfolio, setPortfolio }) => {
    const dispatch = useAppDispatch();
    const [editPortfolio, setEditPortfolio] = useState<any>();
    const [filePhoto, setFilePhoto] = useState(portfolio?.photo !== '' ? process.env.REACT_APP_API_BASE_URL + portfolio?.photo : userDummayImage);
    const [fileForm64, setFileForm64] = useState<string | ArrayBuffer | null>();
    const [fileForm, setFileForm] = useState<any>('');
    const [isEditPortfolioPage, setIsEditPortfolioPage] = useState<boolean>(false);
    const [crop, setCrop] = useState<Crop>();
    const [cropPath, setCropPath] = useState<any>();
    const [croppedImage, setCroppedImage] = useState<string | null>(null);
    const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);

    useEffect(() => {
        if (isEditPortfolioPage) {
            document.getElementById('handleCombineImagesTitle')?.click();
            setIsEditPortfolioPage(false);
        }
    }, [editPortfolio]);
    console.log(fileForm)

    const onCropComplete = useCallback((newCrop: PixelCrop) => {
        if (imageRef && newCrop.width && newCrop.height) {
            const canvas = document.createElement('canvas');
            const scaleX = imageRef.naturalWidth / imageRef.width;
            const scaleY = imageRef.naturalHeight / imageRef.height;
            canvas.width = newCrop.width;
            canvas.height = newCrop.height;
            const ctx = canvas.getContext('2d');

            if (ctx) {
                ctx.drawImage(
                    imageRef,
                    newCrop.x * scaleX,
                    newCrop.y * scaleY,
                    newCrop.width * scaleX,
                    newCrop.height * scaleY,
                    0,
                    0,
                    newCrop.width,
                    newCrop.height
                );
            }

            canvas.toBlob((blob) => {
                if (blob) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setFileForm64(reader.result);
                        setCropPath(reader.result as string);
                    };
                    reader.readAsDataURL(blob);

                    setFileForm(blob);
                }
            });
        }
    }, [imageRef]);

    const onImageLoad = useCallback(
        (event: React.SyntheticEvent<HTMLImageElement>) => {
            const img = event.currentTarget as HTMLImageElement;
            setImageRef(img);

            const initialCrop = {
                unit: '%',
                width: 50,
                aspect: 4 / 5,
            };

            const canvas = document.createElement('canvas');
            const scaleX = img.naturalWidth / img.width;
            const scaleY = img.naturalHeight / img.height;
            const x = (img.width - img.width * initialCrop.width / 100) / 2;
            const y = (img.height - img.height * initialCrop.width / (100 * initialCrop.aspect)) / 2;
            canvas.width = img.naturalWidth * initialCrop.width / 100;
            canvas.height = canvas.width / initialCrop.aspect;

            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.drawImage(
                    img,
                    x * scaleX,
                    y * scaleY,
                    canvas.width,
                    canvas.height,
                    0,
                    0,
                    canvas.width,
                    canvas.height
                );
            }

            canvas.toBlob((blob) => {
                if (blob) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setFileForm64(reader.result);
                        setCropPath(reader.result as string);
                    };
                    reader.readAsDataURL(blob);

                    setFileForm(blob);
                }
            });
        },
        []
    );

    return (
        <>
            <Modal id="createProjectModal" isOpen={selectEditPagePortfolio}
                   toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                   modalClassName="zoomIn" tabIndex={-1} centered >
                <ModalHeader toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Изменение титульной страницы 
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            id: portfolio?.id,
                            child_uid: portfolio?.child_uid,
                            last_name: portfolio?.last_name,
                            name: portfolio?.name,
                            middle_name: portfolio?.middle_name,
                            photo: fileForm,
                            photo_download: fileForm64 ? fileForm64 : `data:image/png;base64,${portfolio?.photoBase64}`,
                            path_photo: portfolio?.photo,
                            path_photo_page: portfolio?.photo_page,
                            id_pattern: portfolio?.pattern_page_portfolio_id
                        }}
                        validationSchema={
                            Yup.object({
                                name: Yup.string().required('Обязательное поле'),
                                last_name: Yup.string().required('Обязательное поле')
                            })
                        }
                        onSubmit={(values) => {
                            dispatch(setIsSend(true));
                            setEditPortfolio({ ...values });
                            setIsEditPortfolioPage(true);
                        }}
                    >
                        {({ errors, touched, setFieldValue }) => (
                            <Form>
                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">
                                            Имя:
                                            <Field name="name"
                                                   className={`form-control ${touched.name && errors.name ? 'is-invalid' : ''}`} />
                                        </label>
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">
                                            Фамилия:
                                            <Field name="last_name"
                                                   className={`form-control ${touched.last_name && errors.last_name ? 'is-invalid' : ''}`} />
                                        </label>
                                    </Col>
                                </Row>

                                <Row className="mb-2">
                                    <Col>
                                        <label className="w-100">
                                            Отчество:
                                            <Field name="middle_name"
                                                   className={`form-control ${touched.middle_name && errors.middle_name ? 'is-invalid' : ''}`} />
                                        </label>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <label className="w-100">
                                            Фото (.jpg, .png):
                                            <Input
                                                name="photo"
                                                type="file"
                                                className={`form-control ${touched.photo && errors.photo ? 'is-invalid' : ''}`}
                                                accept=".png, .jpg"
                                                onChange={(e: any) => {
                                                    const file = e.target.files[0];

                                                    if (file) {
                                                        const reader = new FileReader();
                                                        reader.onloadend = () => {
                                                            setFileForm64(reader.result);
                                                            setFilePhoto(reader.result as string);
                                                        };
                                                        reader.readAsDataURL(file);
                                                    }
                                                }}
                                            />
                                        </label>
                                    </Col>
                                </Row>
                                <Row className="my-2">
                                        {fileForm64 && (
                                            <Col>
                                                <label className="w-100">Выберите область для обрезки:
                                            <ReactCrop
                                                crop={crop}
                                                aspect={4 / 5}
                                                onChange={(newCrop: any) => {
                                                    setCrop(newCrop)
                                                }}
                                                onComplete={onCropComplete}
                                            >
                                                <img src={filePhoto} alt="Crop" onLoad={onImageLoad} />
                                            </ReactCrop>
                                                </label>
                                            </Col>
                                        )}
                                    <Col>
                                        <label className={fileForm64 ? "w-100" : "w-50"}>Загруженное фото:
                                            {(!croppedImage || filePhoto) && (
                                                <img
                                                    src={cropPath ? cropPath : filePhoto}
                                                    className="user-profile-image w-100 h-auto"
                                                    alt="cropped"
                                                />
                                            )}
                                        </label>
                                    </Col>
                                </Row>

                                <div className="hstack gap-2">
                                    <SubmitWithLoading text={'Готово'} />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>

            <TitlePortfolioPage
                dispatch={(value) => dispatch(value)}
                portfolio={editPortfolio}
                setPortfolio={(value) => setPortfolio(value)}
                setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}
            />
        </>
    );
};

export default EditTitlePage;
