import {Application, Container, Graphics, ICanvas, Sprite, Text} from "pixi.js";
import Modal from "../UI/Modal";
import Input from "../UI/Input";
import Button from "../UI/Button";
import gsap from "gsap";
import ScrollBox from "../UI/ScrollBox";
import Block from "./Block";
import {sound} from "@pixi/sound";
import AxiosClient from "../../api/AxiosClient";
import {CheckBox} from "@pixi/ui";


class SettingsShot extends Modal {
    _app: Application<ICanvas>;
    #name:  string;
    #startText: string
    bg = ''
    person = ''
    _input: Input;
    _button: Button;
    mainInfoY = 20
    personY = this.mainInfoY + 50 + 5
    bgY = this.personY + 50 + 5
    texturesY = this.bgY + 50 + 5
    bgSoundY = this.texturesY + 50 + 5
    #block: Block
    constructor(app: Application<ICanvas>, block: Block, onSave: () => void) {
        super(app, 320, app.view.height);
        this._app = app;
        this.alpha = 0;
        this.#block = block
        this.#name = block.name
        this.#startText = block.startText
        this.x = app.view.width - this.width;
        // this.y = app.view.height / 2 - this.height / 2;
        this._button = new Button('Обновить', 300, 50);
        this._button.view.x = this.width / 2 - this._button.view.width / 2;
        this._button.view.y = this.height - this._button.view.height - 10
        this._button.view.onpointerdown = () => {
            if (this.#startText === "") {
                this.showErrorNotification(app, 'Вводное слово не заполнено')
                return;
            }
            if (this.#name === "") {
                this.showErrorNotification(app, 'Укажите название кадра')
                return;
            }
            const soundErr = this.#block.texturesResult.find((el) => {
                if((el.type === 0 && el.textTrue === '') || (el.type === 1 && el.textFalse === '') || (el.type === 2 && el.textNoActive === '') || el.name === ""){
                    return el
                }
            })
            if (soundErr) {
                this.showErrorNotification(app, `Укажите текст для озвучки в объекте ${soundErr.name} или его название`)
                return;
            }
            const soundErrBg = this.#block.soundBg
            if (soundErrBg.id === 0) {
                this.showErrorNotification(app, `Выберите музыку заднего фона`)
                return;
            }

            const imgBg = this.#block.bg
            if (imgBg.id === 0) {
                this.showErrorNotification(app, `Выберите задний фон`)
                return;
            }
            gsap.to(this, {
                alpha: 0, duration: 0.5, onComplete: () => {
                    this.destroy();
                }
            })
            onSave()
            // addShot(this.name)
        };


        const blocks: { index: number, graphics: Graphics }[] = []

        const container = new Container()

        const mainInfo = new Graphics()
        mainInfo.beginFill(0xAEA6D2)
        mainInfo.drawRoundedRect(0, this.mainInfoY, this.width, 50, 0)
        const textMainInfo = new Text('Информация', {fontSize: 25})
        textMainInfo.anchor.set(0.5)
        textMainInfo.position.set(mainInfo.width / 2, this.mainInfoY + mainInfo.height / 2)
        textMainInfo.interactive = false
        mainInfo.addChild(textMainInfo as any)
        mainInfo.cursor = 'pointer'
        blocks.push({index: 1, graphics: mainInfo})
        mainInfo.onpointerdown = () => {
            this.onShow(1, this.mainInfoY, mainInfo, blocks, 400)
        }
        this._input = new Input('Название кадра', 300, 50);
        this._input.x = this.width / 2 - this._input.width / 2;
        this._input.y = this.mainInfoY + 60;
        this._input.value = this.#name;
        this._input.onChange.connect((text) => {
            this.#name = text
            this.#block.name = text
        })

        const inputText = new Input('Вводный текст', 300, 200);
        inputText.x = this.width / 2 - this._input.width / 2;
        inputText.y = this.mainInfoY + 120;
        inputText.value = this.#startText;
        inputText.onChange.connect((text) => {
            this.#startText = text
            this.#block.startText = text
        })

        const checkBox = new CheckBox({
            text: `Использовать GPT`,
            checked: this.#block.isChatGPT,
            style: {
                unchecked: new Graphics()
                    .beginFill('#DCB000')
                    .drawRoundedRect(-2, -2, 50 + 4, 50 + 4, 10)
                    .beginFill('#A5E24D')
                    .drawRoundedRect(0, 0, 50, 50, 10),
                checked: new Graphics()
                    .beginFill('#DCB000')
                    .drawRoundedRect(-2, -2, 50 + 4, 50 + 4, 10)
                    .beginFill('#A5E24D')
                    .drawRoundedRect(0, 0, 50, 50, 10)
                    .beginFill('#FFFFFF')
                    .drawRoundedRect(3, 3, 50 - 6, 50 - 6, 10)
                    .beginFill('#FFFFFF')
                    .drawRoundedRect(5, 5, 50 - 10, 50 - 10, 10),
                text: {
                    fontSize: 22,
                    fill: '#000000'
                }
            }
        });

        checkBox.x = this.width / 2 - checkBox.width / 2;
        checkBox.y = this.mainInfoY + 330;

        checkBox.onCheck.connect((checked) =>
        {
            this.#block.isChatGPT = checked
        });

        const settingsMainInfoBlock = new Container()
        const bgSettingsMainInfoBlock = new Graphics()
        bgSettingsMainInfoBlock.beginFill(0xFFFFFF)
        bgSettingsMainInfoBlock.drawRoundedRect(0, this.mainInfoY + 50, this.width, 400, 0)
        settingsMainInfoBlock.addChild(bgSettingsMainInfoBlock as any)
        settingsMainInfoBlock.addChild(this._input as any)
        settingsMainInfoBlock.addChild(inputText as any)
        settingsMainInfoBlock.addChild(checkBox as any)
        mainInfo.addChild(settingsMainInfoBlock as any)
        mainInfo.children[1].visible = false

        const personage = new Graphics()
        personage.beginFill(0xAEA6D2)
        personage.drawRoundedRect(0, this.personY, this.width, 50, 0)
        const textPerson = new Text('Персонаж', {fontSize: 25})
        textPerson.anchor.set(0.5)
        textPerson.position.set(personage.width / 2, this.personY + personage.height / 2)
        textPerson.interactive = false
        personage.addChild(textPerson as any)
        personage.cursor = 'pointer'
        blocks.push({index: 2, graphics: personage})
        personage.onpointerdown = () => {
            this.onShow(2, this.personY, personage, blocks, 400)
        }
        const scrollBoxPerson = new ScrollBox(this.width, 300);
        scrollBoxPerson.y = this.personY + 60
        scrollBoxPerson.x = this.width / 2 - scrollBoxPerson.width / 2
        AxiosClient.get('/game/personages')
            .then(r => {
                for (let i = 0; i < r.data.length; i++) {
                    const container = new Container()
                    // const border = new Graphics();
                    // border.lineStyle(5, '0xFFFFFF');
                    // border.drawRect(0, 0, this.width - 20 + 5 * 2, this.width / 3.2 + 5 * 2);
                    // border.visible = false
                    // const borderSelect = new Graphics();
                    // borderSelect.lineStyle(5, '0x0000FF');
                    // borderSelect.drawRect(0, 0, this.width / 3.2 + 5 * 2, this.width / 3.2 + 5 * 2);
                    // borderSelect.visible = false
                    const sprite = Sprite.from(r.data[i].base64)
                    sprite.width = this.width - 30
                    sprite.height = this.width - 30
                    sprite.x = 5;
                    sprite.y = 5;
                    sprite.onmouseover = () => {
                        // border.visible = true
                    }
                    sprite.onmouseout = () => {
                        // border.visible = false
                    }
                    sprite.cursor = 'pointer'
                    sprite.onpointerdown = () => {
                        // borderSelect.visible = !borderSelect.visible
                        // settings.visible = !settings.visible
                        // view.visible = true;
                        // this.person = user;
                        this.#block.personage = r.data[i]
                    }
                    // container.addChild(borderSelect as any);
                    // container.addChild(border as any);
                    container.addChild(sprite as any);
                    scrollBoxPerson.addItem(container);
                }
            })
        const settingsPersonageBlock = new Container()
        const bgSettingsPersonageBlock = new Graphics()
        bgSettingsPersonageBlock.beginFill(0xFFFFFF)
        bgSettingsPersonageBlock.drawRoundedRect(0, this.personY + 50, this.width, 350, 0)
        settingsPersonageBlock.addChild(bgSettingsPersonageBlock as any)
        settingsPersonageBlock.addChild(scrollBoxPerson as any)
        personage.addChild(settingsPersonageBlock as any)
        personage.children[1].visible = false

        const bg = new Graphics()
        bg.beginFill(0xAEA6D2)
        bg.drawRoundedRect(0, this.bgY, this.width, 50, 0)
        const textBg = new Text('Фон', {fontSize: 25})
        textBg.anchor.set(0.5)
        textBg.position.set(bg.width / 2, this.bgY + bg.height / 2)
        textBg.interactive = false
        bg.addChild(textBg as any)
        blocks.push({index: 3, graphics: bg})
        bg.cursor = 'pointer'
        bg.onpointerdown = () => {
            this.onShow(3, this.bgY, bg, blocks, 400)
        }
        const scrollBox = new ScrollBox(this.width, 300);
        scrollBox.y = this.bgY + 60
        scrollBox.x = this.width / 2 - scrollBox.width / 2
        AxiosClient.get('game/backgroundImage')
            .then((r) => {
                for (let i = 0; i < r.data.length; i++) {
                    const container = new Container()
                    // const border = new Graphics();
                    // border.lineStyle(5, '0xFFFFFF');
                    // border.drawRect(0, 0, this.width - 20 + 5 * 2, this.width / 3.2 + 5 * 2);
                    // border.visible = false
                    // const borderSelect = new Graphics();
                    // borderSelect.lineStyle(5, '0x0000FF');
                    // borderSelect.drawRect(0, 0, this.width / 3.2 + 5 * 2, this.width / 3.2 + 5 * 2);
                    // borderSelect.visible = false
                    const sprite = Sprite.from(r.data[i].base64)
                    sprite.width = this.width - 30
                    sprite.height = this.width - 30
                    sprite.x = 5;
                    sprite.y = 5;
                    sprite.onmouseover = () => {
                        // border.visible = true
                    }
                    sprite.onmouseout = () => {
                        // border.visible = false
                    }
                    sprite.cursor = 'pointer'
                    sprite.onpointerdown = () => {
                        // borderSelect.visible = !borderSelect.visible
                        // settings.visible = !settings.visible
                        // view.visible = true;
                        this.#block.bg = r.data[i]
                        // this.person = user;
                        // this.removeChild(scrollBoxPerson as any)
                    }
                    // container.addChild(borderSelect as any);
                    // container.addChild(border as any);
                    container.addChild(sprite as any);
                    scrollBox.addItem(container)
                }
            })
        const settingsBgBlock = new Container()
        const bgSettingsBgBlock = new Graphics()
        bgSettingsBgBlock.beginFill(0xFFFFFF)
        bgSettingsBgBlock.drawRoundedRect(0, this.bgY + 50, this.width, 350, 0)
        settingsBgBlock.addChild(bgSettingsBgBlock as any)
        settingsBgBlock.addChild(scrollBox as any)
        bg.addChild(settingsBgBlock as any)
        bg.children[1].visible = false


        const textures = new Graphics()
        textures.beginFill(0xAEA6D2)
        textures.drawRoundedRect(0, this.texturesY, this.width, 50, 0)
        const textTextures = new Text('Объекты', {fontSize: 25})
        textTextures.anchor.set(0.5)
        textTextures.position.set(textures.width / 2, this.texturesY + textures.height / 2)
        textTextures.interactive = false
        textures.addChild(textTextures as any)
        blocks.push({index: 4, graphics: textures})
        textures.cursor = 'pointer'
        textures.onpointerdown = () => {
            this.onShow(4, this.texturesY, textures, blocks, 400)
        }
        const listTextures = new ScrollBox(this.width, 300);
        listTextures.y = this.texturesY + 60
        listTextures.x = this.width / 2 - listTextures.width / 2
        AxiosClient.get('/game/textures')
            .then((r) => {
                for (let i = 0; i < r.data.length; i++) {
                    const container = new Container()
                    // const border = new Graphics();
                    // border.lineStyle(5, '0xFFFFFF');
                    // border.drawRect(0, 0, this.width - 20 + 5 * 2, this.width / 3.2 + 5 * 2);
                    // border.visible = false
                    // const borderSelect = new Graphics();
                    // borderSelect.lineStyle(5, '0x0000FF');
                    // borderSelect.drawRect(0, 0, this.width / 3.2 + 5 * 2, this.width / 3.2 + 5 * 2);
                    // borderSelect.visible = false
                    const sprite = Sprite.from(r.data[i].base64)
                    sprite.width = this.width - 30
                    sprite.height = this.width - 30
                    sprite.x = 5;
                    sprite.y = 5;
                    sprite.onmouseover = () => {
                        // border.visible = true
                    }
                    sprite.onmouseout = () => {
                        // border.visible = false
                    }
                    sprite.cursor = 'pointer'
                    sprite.onpointerdown = () => {
                        // borderSelect.visible = !borderSelect.visible
                        // settings.visible = !settings.visible
                        // view.visible = true;
                        this.#block.textures = r.data[i]
                        // this.person = user;
                        // this.removeChild(scrollBoxPerson as any)
                    }
                    // container.addChild(borderSelect as any);
                    // container.addChild(border as any);
                    container.addChild(sprite as any);
                    listTextures.addItem(container)
                }
            })

        const settingsTexturesBlock = new Container()
        const texturesSettingsBgBlock = new Graphics()
        texturesSettingsBgBlock.beginFill(0xFFFFFF)
        texturesSettingsBgBlock.drawRoundedRect(0, this.texturesY + 50, this.width, 350, 0)
        settingsTexturesBlock.addChild(texturesSettingsBgBlock as any)
        settingsTexturesBlock.addChild(listTextures as any)
        textures.addChild(settingsTexturesBlock as any)
        textures.children[1].visible = false

        const bgSound = new Graphics()
        bgSound.beginFill(0xAEA6D2)
        bgSound.drawRoundedRect(0, this.bgSoundY, this.width, 50, 0)
        const textSound = new Text('Звуки фона', {fontSize: 25})
        textSound.anchor.set(0.5)
        textSound.position.set(bgSound.width / 2, this.bgSoundY + bgSound.height / 2)
        textSound.interactive = false
        bgSound.addChild(textSound as any)
        blocks.push({index: 5, graphics: bgSound})
        bgSound.cursor = 'pointer'
        bgSound.onpointerdown = () => {
            this.onShow(5, this.bgSoundY, bgSound, blocks, 400)
        }
        const listSound = new ScrollBox(this.width, 300);
        listSound.y = this.bgSoundY + 60
        listSound.x = this.width / 2 - listSound.width / 2
        AxiosClient.get('/game/backgroundAudio')
            .then(r => {
                for (let i = 0; i < r.data.length; i++) {
                    const container = new Container()
                    const sprite = new Text(r.data[i].name, {fontSize: 30, align: "center"})
                    sprite.x = 5;
                    sprite.y = 5;
                    sprite.onmouseover = () => {
                        // border.visible = true
                    }
                    sprite.onmouseout = () => {
                        // border.visible = false
                    }
                    sprite.cursor = 'pointer'
                    sprite.onpointerdown = () => {
                        this.#block.soundBg = r.data[i]
                        if(!sound.exists(String(i))) {
                            sound.add(String(i), r.data[i].base64);
                        }
                        const mySound = sound.find(String(i));
                        if (mySound) {
                            mySound.play({
                                start: 0, // Начинаем воспроизведение с начала
                            });

                            // Останавливаем воспроизведение через 5 секунд
                            setTimeout(() => {
                                mySound.stop(); // Останавливаем звук
                            }, 5000);
                        }
                    }
                    container.addChild(sprite as any);
                    listSound.addItem(container)
                }
            })
        const settingsSoundBlock = new Container()
        const soundSettingsBgBlock = new Graphics()
        soundSettingsBgBlock.beginFill(0xFFFFFF)
        soundSettingsBgBlock.drawRoundedRect(0, this.bgSoundY + 50, this.width, 350, 0)
        settingsSoundBlock.addChild(soundSettingsBgBlock as any)
        settingsSoundBlock.addChild(listSound as any)
        bgSound.addChild(settingsSoundBlock as any)
        bgSound.children[1].visible = false


        // plus.onpointerdown = () => {
        //     scrollBox.removeItems()
        //     for (let i = 0; i < 5; i++) {
        //         const container = new Container()
        //         const border = new Graphics();
        //         border.lineStyle(5, '0xFFFFFF');
        //         border.drawRect(0, 0, this.width / 3.2 + 5 * 2, this.width / 3.2 + 5 * 2);
        //         border.visible = false
        //         const texture = Texture.from(bg)
        //         const sprite = new Sprite()
        //         sprite.texture = texture
        //         sprite.width = this.width / 3.2
        //         sprite.height = this.width / 3.2
        //         sprite.x = 5;
        //         sprite.y = 5;
        //         sprite.onmouseover = () => {
        //             border.visible = true
        //         }
        //         sprite.onmouseout = () => {
        //             border.visible = false
        //         }
        //         sprite.cursor = 'pointer'
        //         sprite.onpointerdown = () => {
        //             view.visible = true;
        //             this.bg = bg;
        //             this.removeChild(scrollBox as any)
        //         }
        //         container.addChild(border as any);
        //         container.addChild(sprite as any);
        //         scrollBox.addItem(container);
        //     }
        //     this.addChild(scrollBox as any)
        //     view.visible = false
        // }
        //
        // const peopleTexture = Texture.from(people)
        // const peopleSprite = new Sprite()
        // view.addChild(peopleSprite as any)
        // peopleSprite.anchor.set(0.5)
        // peopleSprite.cursor = 'pointer'
        // peopleSprite.texture = peopleTexture;
        // peopleSprite.width = 30;
        // peopleSprite.height = 30;
        // peopleSprite.x = 30 + 40;
        // peopleSprite.y = this.height - 25;
        // peopleSprite.onpointerdown = () => {
        //     scrollBoxPerson.removeItems()
        //     for (let i = 0; i < 5; i++) {
        //         const container = new Container()
        //         const border = new Graphics();
        //         border.lineStyle(5, '0xFFFFFF');
        //         border.drawRect(0, 0, this.width / 3.2 + 5 * 2, this.width / 3.2 + 5 * 2);
        //         border.visible = false
        //         const borderSelect = new Graphics();
        //         borderSelect.lineStyle(5, '0x0000FF');
        //         borderSelect.drawRect(0, 0, this.width / 3.2 + 5 * 2, this.width / 3.2 + 5 * 2);
        //         borderSelect.visible = false
        //         const texture = Texture.from(user)
        //         const sprite = new Sprite()
        //         sprite.texture = texture
        //         sprite.width = this.width / 3.2
        //         sprite.height = this.width / 3.2
        //         sprite.x = 5;
        //         sprite.y = 5;
        //         sprite.onmouseover = () => {
        //             border.visible = true
        //         }
        //         sprite.onmouseout = () => {
        //             border.visible = false
        //         }
        //         sprite.cursor = 'pointer'
        //         sprite.onpointerdown = () => {
        //             borderSelect.visible = !borderSelect.visible
        //             // settings.visible = !settings.visible
        //             // view.visible = true;
        //             this.person = user;
        //             // this.removeChild(scrollBoxPerson as any)
        //         }
        //         container.addChild(borderSelect as any);
        //         container.addChild(border as any);
        //         container.addChild(sprite as any);
        //         scrollBoxPerson.addItem(container);
        //     }
        //     this.addChild(scrollBoxPerson as any)
        //     view.visible = false
        // }
        container.addChild(bgSound as any)
        container.addChild(mainInfo as any)
        container.addChild(personage as any)
        container.addChild(bg as any)
        container.addChild(textures as any)
        // container.addChild(plus as any)
        // this._app.stage.addChild(settings as any)
        this.addChild(container as any);
        this.addChild(this._button.view as any)
    }

    showErrorNotification(app: Application<ICanvas>, message: string) {
        // Создание контейнера для уведомления
        const notificationContainer = new Container();
        app.stage.addChild(notificationContainer as any);

        // Фон уведомления
        const background = new Graphics();
        background.beginFill(0xff4444); // Красный цвет для ошибки
        background.drawRoundedRect(0, 0, app.view.width, 50, 10); // Прямоугольник с закругленными углами
        background.endFill();
        notificationContainer.addChild(background as any);

        // Текст уведомления
        const errorText = new Text(message, {
            fontFamily: 'Arial',
            fontSize: 24,
            fill: 0xffffff,
            align: 'center',
        });
        errorText.x = (app.view.width - errorText.width) / 2;
        errorText.y = 10;
        notificationContainer.addChild(errorText as any);

        // Устанавливаем начальное положение контейнера (за экраном)
        notificationContainer.y = -60;

        // Анимация всплывания
        gsap.to(notificationContainer, {
            y: 0, // Позиция сверху экрана
            duration: 0.5, // Продолжительность анимации
            ease: "power1.out",
            onComplete: () => {
                // Задержка перед исчезновением
                gsap.to(notificationContainer, {
                    delay: 2, // Ожидание перед исчезновением
                    y: -60, // Убираем за экран
                    duration: 0.5, // Продолжительность исчезновения
                    ease: "power1.in",
                    onComplete: () => {
                        app.stage.removeChild(notificationContainer as any); // Удаляем уведомление после анимации
                    }
                });
            }
        });
    }

    onShow(index: number, y: number, graphic: Graphics, blocks: {
        index: number,
        graphics: Graphics
    }[], newHeight: number, oldHeight = 50) {
        if (graphic.height === 50) {
            gsap.to(graphic, {
                duration: 0, onUpdate: () => {
                    graphic.clear();
                    graphic.beginFill(0xAEA6D2);
                    graphic.drawRoundedRect(0, y, this.width, newHeight, 0);
                }, onComplete: () => {
                    blocks.forEach((block, i) => {
                        if (block.index > index) {
                            block.graphics.y += (newHeight - oldHeight)
                        }
                    })
                    graphic.children[1].visible = true
                }
            });
            //personage.children[1].visible = true;
        } else {
            gsap.to(graphic, {
                duration: 0, onUpdate: () => {
                    graphic.clear();
                    graphic.beginFill(0xAEA6D2);
                    graphic.drawRoundedRect(0, y, this.width, oldHeight, 0);
                }, onComplete: () => {
                    blocks.forEach((block, i) => {
                        if (block.index > index) {
                            block.graphics.y -= (newHeight - oldHeight)
                        }
                    })
                    graphic.children[1].visible = false
                }
            });
            //personage.children[1].visible = false;
        }
    }
}

export default SettingsShot;