import React, {FC, MouseEvent} from 'react';
import {Badge, Card, CardBody, CardFooter} from "reactstrap";
import bg from "../../assetsGame/icons/bgStartEnd.jpg";
import {Link, useNavigate} from "react-router-dom";

const CardGameTeacher: FC<{id: number, name: string, removeGame: (event: MouseEvent<HTMLElement, globalThis.MouseEvent>, id: number) => void}> = ({id, name, removeGame}) => {
    const navigate = useNavigate()

    const update = (id: number) => {
        navigate(`/games/update/${id}`)
    }

    return (
        <Link to={`/game/${id}`} className="w-100">
            <Card>
                <CardBody>
                    <Badge color="primary" className="p-2 position-absolute" style={{left: '5px', top: '5px'}} onClick={(e) => {
                        e.preventDefault()
                        update(id)
                    }}><i className="bx bx-edit fs-23"></i></Badge>
                    <Badge color="danger" className="p-2 position-absolute" style={{right: '5px', top: '5px'}} onClick={(event) => removeGame(event, id)}><i className="bx bx-trash fs-23"></i></Badge>
                    <img src={bg} alt="bg" className="w-100 h-100"/>
                </CardBody>
                <CardFooter>
                    <div>
                        <h4 className="m-0">{name}</h4>
                    </div>
                </CardFooter>
            </Card>
        </Link>
    );
};

export default CardGameTeacher;
