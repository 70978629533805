import {Application, Container, ICanvas} from "pixi.js";
import Menu from "./Menu";
import Size from "../utils/Size";
import {Viewport} from "pixi-viewport";
import Shot from "./Shot";
import AxiosClient from "../../api/AxiosClient";
import {IGame} from "../models/IGame";
class Game {
    _app: Application<ICanvas>;
    shots: Shot[] = [];
    constructor(app: Application<ICanvas>, idGame: number) {
        this._app = app
        const scene = new Container()
        const size = new Size(app)
        scene.width = 200;
        scene.height = app.view.height;
        const viewport = new Viewport({
            screenWidth: app.view.width,
            screenHeight: app.view.height,
            worldWidth: 500,
            worldHeight: 700,

            events: app.renderer.events
        })

        AxiosClient.get<IGame>(`/game/game/${idGame}`)
            .then((r) => {
                const startModal = new Menu(this._app, viewport, r.data);
                scene.addChild(startModal as any)
                viewport.addChild(scene as any)
                this._app.stage.addChildAt(viewport as any, 0)
            })

        viewport
            // .drag()
            // .pinch()
            // .wheel()
            // .decelerate({friction: 0.99})
            .fit(true, size.viewport({xxs: 2.5, xs: 2, sm: 1.2, lg: 1.2, xl: 1, xxl: 1}, app.view.width), app.view.height)
    }
}

export default Game;