import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardFooter, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import AxiosClient from "../../api/AxiosClient";
import bg from '../../assetsGame/icons/bgStartEnd.jpg'

const ListGames = () => {
    const [games, setGames] = useState<{ id: number, name: string, completed_games_count: number, likes_count: number, isLike: boolean }[]>([]);
    useEffect(() => {
        AxiosClient.get('/game')
            .then((r) => setGames(r.data))
    }, []);

    const like = (id:number) => {
        AxiosClient.post(`/game/like/${id}`)
            .then(() => setGames(prevState => prevState.map(el => {
                if(el.id === id) {
                    return {
                        ...el,
                        isLike: true,
                        likes_count: el.likes_count + 1
                    }
                }
                return el;
            })))
    }

    const unlike = (id:number) => {
        AxiosClient.post(`/game/unlike/${id}`)
            .then((r) => setGames(prevState => prevState.map(el => {
                if(el.id === id) {
                    return {
                        ...el,
                        isLike: false,
                        likes_count: el.likes_count - 1
                    }
                }
                return el;
            })))
    }

    return (
        <div>
            <Row>
                {
                    games.map(el => (
                        <Col xxl={3} xl={4} lg={6} key={el.id}>
                            <Link to={`/game/${el.id}`} className="w-100">
                                <Card>
                                    <CardBody>
                                        <img src={bg} alt="bg" className="w-100 h-100"/>
                                    </CardBody>
                                    <CardFooter>
                                        <div>
                                            <h4 className="m-0">{el.name}</h4>
                                        </div>
                                        <div style={{fontSize: 30}} className="d-flex">
                                            {
                                                !el.isLike ?
                                                    <div className="d-flex align-items-center flex-1 justify-content-center" onClick={(e) => {
                                                        e.preventDefault()
                                                        like(el.id)
                                                    }}>
                                                        <i className="bx bx-like"></i>
                                                        <p className="m-0 mx-3">{el.likes_count}</p>
                                                    </div> :
                                                    <div className="d-flex align-items-center flex-1 justify-content-center" onClick={(e) => {
                                                        e.preventDefault()
                                                        unlike(el.id)
                                                    }}>
                                                        <i className="bx bxs-like"></i>
                                                        <p className="m-0 mx-3">{el.likes_count}</p>
                                                    </div>
                                            }
                                            <div className="d-flex align-items-center flex-1 justify-content-center">
                                                <i className="bx bx-show"></i>
                                                <p className="m-0 mx-3">{el.completed_games_count}</p>
                                            </div>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Link>
                        </Col>
                    ))
                }
            </Row>
        </div>
    );
};

export default ListGames;
