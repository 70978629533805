import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import AxiosClient from "../../../../api/AxiosClient";
import {toPng} from "html-to-image";
import {setIsSend} from "../../../../store/reducers/widgets/WidgetsSlice";

interface IAboutMe {
    dispatch(value: any): any,
    page: any,
    setPortfolio(value: any): any,
    setSelectEditPagePortfolio(value: boolean): any,
}

const AboutMePortfolio: FC<IAboutMe> = ({ dispatch, page, setPortfolio, setSelectEditPagePortfolio }) => {
    const [images, setImages] = useState<{img1: any, img2: any}>({ img1: '', img2: '' });
    const ref = useRef<HTMLDivElement>(null);

    const handleCombineImagesAboutMe = () => {
        AxiosClient.post('get/pattern/portfolio', { nameImage: 'aboutMe.png',  id_type: page?.selectPage?.type_page_portfolio_id, id_pattern: page?.id_pattern})
            .then(r => {
                let img2 = page?.photo_download_base64?.[0];

                if (img2 && !img2.startsWith('data:image/png;base64,')) {
                    img2 = 'data:image/png;base64,' + img2;
                }

                setImages({ img1: r.data.img1, img2: img2 });
            })
            .catch(() => console.log('error'));
    };

    useEffect(() => {
        onButtonClick();
    }, [images])

    const onButtonClick = useCallback(() => {
        const image = new Image();
        image.src = `data:image/png;base64,${images.img1}`;
        image.onload = () => {
            if (ref.current === null || !images.img1) {
                return;
            }
            toPng(ref.current, { cacheBust: true })
                .then((dataUrl) => {
                    fetch(dataUrl)
                        .then(res => res.blob())
                        .then(blob => {
                            const formData = new FormData();
                            formData.append('photo_page', blob);
                            formData.append('id_portfolio', page?.selectPage.portfolio_child_id);
                            formData.append('uid', page?.uid);
                            const content = {
                                name: page?.name,
                                date: page?.date,
                                zodiac: page?.zodiac,
                                address: page?.address,
                            };
                            formData.append('content', JSON.stringify(content));
                            formData.append('photo1', page.photo);
                            formData.append('countPhoto', page.photo ? '1' : '0');
                            formData.append('list_path_photo', page?.path_photo);
                            formData.append('path_photo_page', page?.path_photo_page);

                            AxiosClient.post(`update/page/parent/portfolio/${page.selectPage.id}`, formData, { headers: { "Content-Type": 'multipart/form-data' } })
                                .then(r => {
                                    setPortfolio(r.data);
                                    setSelectEditPagePortfolio(false);
                                    dispatch(setIsSend(false))
                                })
                                .catch(error => {
                                    console.error("Ошибка при выгрузке страницы:", error);
                                });
                        })
                        .catch(error => console.error('Ошибка при создании Blob:', error));
                })
                .catch((err) => {
                    console.log(err);
                });
        };
    }, [ref, images, page, dispatch, setPortfolio, setSelectEditPagePortfolio]);

    return (
        < div
            ref={ref}
            style={{
                position: 'relative',
                height: '3508px',
                width: '2480px',
                zIndex: 1,
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    marginLeft: '65px',
                    marginTop: '710px',
                    width: '1140px',
                    height: '1460px',
                    backgroundImage: `url('${images.img2}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 2,
                }}
            ></div>
            {/*<img*/}
            {/*    src={images.img2}*/}
            {/*    alt="Pattern from backend"*/}
            {/*    style={{*/}
            {/*        position: 'absolute',*/}
            {/*        marginLeft: '615px',*/}
            {/*        marginTop: '710px',*/}
            {/*        transform: 'translateX(-50%)',*/}
            {/*        width: '1140px',*/}
            {/*        height: '1460px',*/}
            {/*        zIndex: 1,*/}
            {/*    }}*/}
            {/*/>*/}
            <div

                style={{
                    position: 'absolute',
                    padding: '10px',
                    height: '3508px',
                    width: '2480px',
                    backgroundImage: `url('data:image/png;base64,${images.img1}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    zIndex: 3,
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        zIndex: 4,
                        fontSize: '100px',
                        fontFamily: 'domino',
                        color: '#491584',
                        marginLeft: '1250px',
                        marginTop: '800px',
                        maxWidth: '1150px',
                        padding: '50px',
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        borderRadius: '20px',
                        boxShadow: '0 0 30px rgba(0, 0, 0, 0.7)',
                    }}
                >
                    <p>Меня зовут: {page?.name}</p>
                    <p>Я родился: {page?.date}</p>
                    <p>Знак зодиака: {page?.zodiac}</p>
                    <p>Я живу: {page?.address}</p>
                </div>
            </div>
            <button onClick={handleCombineImagesAboutMe} id='handleCombineImagesAboutMe'>Click me</button>
        </div>
    );
};

export default AboutMePortfolio;
