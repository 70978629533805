import {Application, Container, ICanvas} from "pixi.js";
import MenuShot from "./MenuShot";
import ScrollBox from "../UI/ScrollBox";
import {Viewport} from "pixi-viewport";
import Block from "./Block";
import {IGame} from "../models/IGame";


class SceneGame extends Container {
    _app: Application<ICanvas>
    _viewport: Viewport;
    shots: Block[] = []
    _game: IGame
    constructor(app: Application<ICanvas>, viewport: Viewport, game: IGame) {
        super();
        this._app = app;
        this._viewport = viewport;
        this._game = game
        const scrollBox = new ScrollBox(app.view.width - 320, app.view.height - 50);
        scrollBox.y = 50
        viewport.addChild(scrollBox as any)

        game.shots.forEach((el, index) => {
            const block = new Block(this._app, this._viewport, scrollBox, el.name, el.id)
            block.startText = el.startText
            block.index = index
            block.soundBg = el.background_sound
            block.setOldBg(el.background)
            block.isChatGPT = el.isGPTChat

            el.personages.forEach((personage) => {
                block.setOldPersonages(personage)
            })

            el.textures.forEach((texture) => {
                block.setOldTextures(texture)
            })

            block.copyBlock = () => {
                const copyBlock = new Block(this._app, this._viewport, scrollBox, el.name + ' 1')
                copyBlock.startText = block.startText
                copyBlock.index = scrollBox.items.length
                copyBlock.soundBg = block.soundBg
                copyBlock.setOldBg(block.bg)
                copyBlock.copyBlock = () => block.copyBlock()

                copyBlock.personageResult = block.personageResult
                copyBlock.texturesResult = block.texturesResult

                this.shots.push(copyBlock)
                scrollBox.addItem(copyBlock)
            }
            this.shots.push(block)
            scrollBox.addItem(block)
        })
        const menuShot = new MenuShot(app, viewport, scrollBox, 300, 40, (data) => {
            data.index = scrollBox.items.length
            data.copyBlock = () => {
                const copyBlock = new Block(this._app, this._viewport, scrollBox, data.name + ' 1')
                copyBlock.startText = data.startText
                copyBlock.index = scrollBox.items.length
                copyBlock.soundBg = data.soundBg
                copyBlock.setOldBg(data.bg)
                copyBlock.personageResult = data.personageResult
                copyBlock.texturesResult = data.texturesResult
                copyBlock.copyBlock = () => data.copyBlock()
                this.shots.push(copyBlock)
                scrollBox.addItem(copyBlock)
            }
            scrollBox.addItem(data as any)
            this.shots.push(data)
        })
        menuShot.x = app.view.width - menuShot.width - 5
        menuShot.y = app.view.height - menuShot.height - 5
        this.name = 'sceneGame'
        // viewport.addChild(menuShot as any)
        this.addChild(scrollBox as any)
        this.addChild(menuShot as any)
    }
}

export default SceneGame