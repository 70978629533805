import {Application, Graphics, ICanvas} from "pixi.js";
import Button from "../UI/Button";
import Input from "../UI/Input";
import Modal from "../UI/Modal";
import gsap from "gsap";
import SceneGame from "./SceneGame";
import {Viewport} from "pixi-viewport";
import {IGame} from "../models/IGame";
class GameNameModal extends Modal {

    name = '';
    error = false
    _app: Application<ICanvas>
    _input: Input;
    _button: Button;
    _viewport: Viewport
    sceneGame: SceneGame | undefined
    _game: IGame
    constructor(app: Application<ICanvas>, viewport: Viewport, game: IGame) {
        super(app);
        this.alpha = 0
        this._viewport = viewport;
        this._game = game
        this.name = game.name
        this.x = app.view.width / 2 - this.width / 2;
        this.y = app.view.height / 2 - this.height / 2;
        const bgBlur = new Graphics()
        bgBlur.beginFill(0xAEA6D2)
        bgBlur.drawRoundedRect(0, 0, app.view.width, app.view.height, 0)
        bgBlur.alpha = 0.3
        this._button = new Button('Подтвердить', 300, 50);
        this._button.view.x = this.width / 2 - this._button.view.width / 2;
        this._button.view.y = this.height - this._button.view.height - 10
        this._button.view.onpointerdown = () => {
            bgBlur.destroy();
            this.nextModal();
        }
        this._input = new Input('Название игры', 500, 50);
        this._input.x = this.width / 2 - this._input.width / 2;
        this._input.y = this.height / 2 - this._input.height;
        this._input.value = this.name;
        this._input.onChange.connect((text) => {
            this.name = text
        })
        this._app = app
        viewport.addChild(bgBlur as any)
        this.addChild(this._input as any);
        this.addChild(this._button.view as any)
    }

    nextModal() {
        if (this.name === ''){
            this._input.error = true;
        }
        if (this.name !== ''){
            this._input.error = false;
            gsap.to(this, {alpha: 0, duration: 0.3, onComplete: () => {
                    this.visible = false;
                    this.interactiveChildren = false;
                }});
            const sceneGame = new SceneGame(this._app, this._viewport, this._game)
            this.sceneGame = sceneGame
            this._app.stage.addChild(sceneGame as any)
            // this._viewport.addChild(sceneGame as any);
            gsap.to(sceneGame, {alpha: 1, duration: 0.5});
        }
    }
}

export default GameNameModal;