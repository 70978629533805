import {Application, Container, Graphics, ICanvas, Sprite, Text} from "pixi.js";
import Shot from "../Constructor/Shot";
import Size from "../utils/Size";
import {Viewport} from "pixi-viewport";
import AxiosClient from "../../api/AxiosClient";
import {IGame} from "../models/IGame";
import {Sound, sound} from "@pixi/sound";
import Btn from '../../assetsGame/b_3.png'
import bgStartEnd from '../../assetsGame/icons/bgStartEnd.jpg'
import gsap from "gsap";
import micro from "../../assetsGame/icons/microphone.png";
import {FFmpeg} from "@ffmpeg/ffmpeg";
import {fetchFile} from "@ffmpeg/util";
import {TypeTexture} from "../models/ITextures";
import bgResult from '../../assetsGame/f.png'
class Game {
    _app: Application<ICanvas>;
    shots: Shot[] = [];
    constructor(app: Application<ICanvas>, idGame: number) {
        this._app = app
        const size = new Size(app)
        const viewport = new Viewport({
            screenWidth: app.view.width,
            screenHeight: app.view.height,
            worldWidth: app.view.width,
            worldHeight: app.view.height,

            events: app.renderer.events
        })
        const onePercentX = app.view.width / 100
        const onePercentY = app.view.height / 100
        const shots = new Container();
        const start = Sprite.from(bgStartEnd)
        start.anchor.set(0.5)
        start.width = app.view.width
        start.height = app.view.height
        start.x = app.view.width / 2
        start.y = app.view.height / 2
        const btn = Sprite.from(Btn)
        btn.cursor = 'pointer'
        btn.interactiveChildren = false
        btn.anchor.set(0.5)
        btn.scale.set(0.7)
        const text = new Text('Начать играть', {fontSize: 30})
        text.anchor.set(0.5)
        text.scale.set(3.5)
        text.x = btn.width / 2
        text.y = btn.height / 2
        btn.addChild(text as any)
        start.addChild(btn as any)
        btn.onpointerdown = () => {
            start.destroy()
            viewport.addChild(shots as any);
            AxiosClient.get<IGame>(`/game/game/${idGame}`)
                .then((r) => {
                    const arrShots: Container[] = []
                    const arrBgSound: Sound[] = []
                    const arrQuestion: Sound[] = []
                    let shotIndex = 0;
                    let question = r.data.shots.length
                    let trys = 0
                    let trysTrue = 0
                    let trysFalse = 0
                    r.data.shots.forEach((sh) => {
                        const shot = new Container()
                        const bgImage = Sprite.from(sh.background.base64)
                        bgImage.width = app.view.width
                        bgImage.height = app.view.height
                        const maskBgImage = new Graphics();
                        maskBgImage.beginFill(0xFFFFFF);
                        maskBgImage.drawRoundedRect(0, 0,  app.view.width, app.view.height, 10)
                        maskBgImage.endFill();
                        bgImage.mask = maskBgImage;
                        shot.addChild(bgImage as any)

                        if(sh.isGPTChat) {
                            const microRecord = this.microRecord()
                            shot.addChild(microRecord as any)
                        }

                        sh.personages.forEach((el) => {
                            const personage = Sprite.from(el.base64);
                            personage.x = el.pivot.percentX ? onePercentX * el.pivot.percentX : el.pivot.x
                            personage.y = el.pivot.percentY ? onePercentY * el.pivot.percentY : el.pivot.y
                            personage.scale.set(el.pivot.size)
                            personage.anchor.set(0.5);
                            personage.onpointerdown = () => {
                                const mySound = sound.find(String(sh.id));
                                if (mySound) {
                                    mySound.play({
                                        volume: 1
                                    });
                                }
                            }
                            shot.addChild(personage as any)
                        })
                        let truesTexture = sh.textures.filter((textureSearch) => textureSearch.pivot.type === TypeTexture.TRUE).length
                        sh.textures.forEach((el) => {
                            const texture = Sprite.from(el.base64);
                            texture.x = el.pivot.percentX ? onePercentX * el.pivot.percentX : el.pivot.x
                            texture.y = el.pivot.percentY ? onePercentY * el.pivot.percentY : el.pivot.y
                            texture.scale.set(el.pivot.size)
                            texture.anchor.set(0.5);
                            gsap.to(texture.scale, {
                                duration: 1,       // Продолжительность анимации в секундах
                                x: el.pivot.size + 0.1,            // Максимальный масштаб по X (увеличение в 1.5 раза)
                                y: el.pivot.size + 0.1,            // Максимальный масштаб по Y (увеличение в 1.5 раза)
                                repeat: -1,        // Бесконечный повтор
                                yoyo: true,        // Анимация будет возвращаться к исходному масштабу
                                ease: "power1.inOut" // Плавное увеличение и уменьшение
                            });

                            texture.onmouseover = () => {
                                texture.scale.set(el.pivot.size + 0.1)
                            }
                            texture.onmouseout = () => {
                                texture.scale.set(el.pivot.size)
                            }
                            texture.onpointerdown = () => {
                                if (el.pivot.type === 0){
                                    const id = el.id + 'true'
                                    if(!sound.exists(String(id))) {
                                        sound.add(String(id), el.base64TextTrue);
                                    }
                                    const mySound = sound.find(String(id));
                                    if (mySound) {
                                        const ques = sound.find(String(sh.id));
                                        if (ques.isPlaying){
                                            ques.stop()
                                        }
                                        mySound.play({
                                            volume: 1,
                                            complete: () => {
                                                shotIndex++
                                                if (shotIndex < arrShots.length){
                                                    truesTexture -= 1
                                                    trys += 1
                                                    trysTrue += 1
                                                    if(truesTexture <= 0)
                                                        this.showShot(arrShots[shotIndex], shots, arrBgSound[shotIndex], arrQuestion[shotIndex]);
                                                    else {
                                                        shotIndex--
                                                    }
                                                } else {
                                                    AxiosClient.post(`/game/completed_game/${idGame}`).catch(() => console.log('error'))
                                                    const mySound = sound.find(String(sh.background_sound.id));
                                                    mySound.stop()
                                                    shots.destroy()
                                                    const end = Sprite.from(bgStartEnd)
                                                    end.anchor.set(0.5)
                                                    end.width = app.view.width
                                                    end.height = app.view.height
                                                    end.x = app.view.width / 2
                                                    end.y = app.view.height / 2

                                                    const result = Sprite.from(bgResult)
                                                    result.anchor.set(0.5)
                                                    result.width = app.view.width * 1.3
                                                    result.height = app.view.height / 1.3
                                                    result.x = 0
                                                    result.y = -120

                                                    const textQuestion = new Text(`Вопросов ${question}`, {fontSize: 30})
                                                    textQuestion.anchor.set(0.5)
                                                    textQuestion.scale.set(3.5)
                                                    textQuestion.y -= textQuestion.height * 2
                                                    result.addChild(textQuestion as any)

                                                    const textTrys = new Text(`Попыток ${trys}`, {fontSize: 30})
                                                    textTrys.anchor.set(0.5)
                                                    textTrys.scale.set(3.5)
                                                    textTrys.y -= textTrys.height
                                                    result.addChild(textTrys as any)

                                                    const textTrysTrue = new Text(`Правильных ответов ${trysTrue}`, {fontSize: 30})
                                                    textTrysTrue.anchor.set(0.5)
                                                    textTrysTrue.scale.set(3.5)
                                                    result.addChild(textTrysTrue as any)

                                                    const textTrysFalse = new Text(`Не правильных ответов ${trysFalse}`, {fontSize: 30})
                                                    textTrysFalse.anchor.set(0.5)
                                                    textTrysFalse.scale.set(3.5)
                                                    textTrysFalse.y += textTrysFalse.height
                                                    result.addChild(textTrysFalse as any)

                                                    const games = Sprite.from(Btn)
                                                    games.y -= -app.view.height + games.height + 100
                                                    games.x -= games.width / 2
                                                    games.cursor = 'pointer'
                                                    games.interactiveChildren = false
                                                    games.anchor.set(0.5)
                                                    games.scale.set(0.7)
                                                    games.onpointerdown = () => {
                                                        window.location.href = '/games'
                                                    }
                                                    const text = new Text('К играм', {fontSize: 30})
                                                    text.anchor.set(0.5)
                                                    text.scale.set(3.5)

                                                    const restart = Sprite.from(Btn)
                                                    restart.y -= -app.view.height + restart.height + 100
                                                    restart.x += restart.width / 2
                                                    restart.cursor = 'pointer'
                                                    restart.interactiveChildren = false
                                                    restart.anchor.set(0.5)
                                                    restart.scale.set(0.7)
                                                    restart.onpointerdown = () => {
                                                        window.location.reload()
                                                    }
                                                    const textRestart = new Text('Начать заного', {fontSize: 30})
                                                    textRestart.anchor.set(0.5)
                                                    textRestart.scale.set(3.5)

                                                    //end.addChild(result as any)
                                                    restart.addChild(textRestart as any)
                                                    end.addChild(restart as any)
                                                    games.addChild(text as any)
                                                    end.addChild(games as any)
                                                    viewport.addChild(end as any)
                                                }
                                            }
                                        });
                                    }
                                }
                                if (el.pivot.type === 1){
                                    trys += 1
                                    trysFalse += 1
                                    const id = el.id + 'false'
                                    if(!sound.exists(String(id))) {
                                        sound.add(String(id), el.base64TextFalse);
                                    }
                                    const mySound = sound.find(String(id));
                                    if (mySound) {
                                        const ques = sound.find(String(sh.id));
                                        if (ques.isPlaying){
                                            ques.stop()
                                        }
                                        mySound.play({
                                            volume: 1
                                        });
                                    }
                                }
                                if (el.pivot.type === 2){
                                    const id = el.id + 'noActive'
                                    if(!sound.exists(String(id))) {
                                        sound.add(String(id), el.base64TextNoActive);
                                    }
                                    const mySound = sound.find(String(id));
                                    if (mySound) {
                                        const ques = sound.find(String(sh.id));
                                        if (ques.isPlaying){
                                            ques.stop()
                                        }
                                        mySound.play({
                                            volume: 1
                                        });
                                    }
                                }
                            }
                            shot.addChild(texture as any)
                        })
                        if(!sound.exists(String(sh.background_sound.id))) {
                            sound.add(String(sh.background_sound.id), sh.background_sound.base64);
                        }
                        const mySound = sound.find(String(sh.background_sound.id));
                        if (mySound) {
                            arrBgSound.push(mySound)
                        }
                        if(!sound.exists(String(sh.id))) {
                            sound.add(String(sh.id), sh.base64);
                        }
                        const ques = sound.find(String(sh.id));
                        arrQuestion.push(ques)
                        arrShots.push(shot)
                    })
                    this.showShot(arrShots[shotIndex], shots, arrBgSound[shotIndex], arrQuestion[shotIndex]);
                })
        }
        viewport.addChild(start as any)
        this._app.stage.addChildAt(viewport as any, 0)

        viewport
            // .drag()
            // .pinch()
            // .wheel()
            // .decelerate({friction: 0.99})
            .fit(true, size.viewport({xxs: 2.5, xs: 1, sm: 1, lg: 1, xl: 1, xxl: 1}, app.view.width), app.view.height)
    }

    showShot(shot: Container, shots: Container, bgSound: Sound, ques: Sound) {
        shots.removeChildren();
        bgSound.play({
            loop: true,
            volume: 0.01
        });
        ques.play({
            volume: 1
        });
        shots.addChild(shot as any);
    }

    microRecord() {
        let mediaRecorder: MediaRecorder | null = null;
        let chunks: Blob[] = [];
        const bgMicro = new Graphics();
        bgMicro.beginFill(0xFFFFFF); // Белый цвет
        bgMicro.drawCircle(0, 0, 32);
        bgMicro.endFill();
        bgMicro.x = this._app.view.width - bgMicro.width / 2 - 5
        bgMicro.y = this._app.view.height - bgMicro.height / 2 - 5
        bgMicro.interactiveChildren = false
        bgMicro.onpointerdown = async () => {
            try {
                if (!mediaRecorder || mediaRecorder.state === 'inactive') {
                    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                    mediaRecorder = new MediaRecorder(stream);

                    mediaRecorder.ondataavailable = (e) => {
                        chunks.push(e.data);
                    };

                    mediaRecorder.onstop = async () => {
                        let audioBlob = new Blob(chunks, { type: 'audio/wav' });
                        audioBlob = await this.createWavBlob(audioBlob);
                        this.downloadBlob(audioBlob, 'output.wav');
                        // this.sendAudioToServer(audioBlob)
                        chunks = [];
                    };

                    mediaRecorder.start();
                    console.log('Recording started');
                    bgMicro.tint = 0xff0000;  // Изменение цвета кнопки на красный, когда запись активна
                }
            } catch (error) {
                console.error('Error accessing microphone:', error);
            }
        }

        bgMicro.onpointerup = () => {
            if (mediaRecorder && mediaRecorder.state === 'recording') {
                mediaRecorder.stop();
                console.log('Recording stopped');
                bgMicro.tint = 0xffffff;  // Вернуть цвет кнопки обратно в белый
            }
        }

        bgMicro.onpointerupoutside = () => {
            if (mediaRecorder && mediaRecorder.state === 'recording') {
                mediaRecorder.stop();
                console.log('Recording stopped');
                bgMicro.tint = 0xffffff;  // Вернуть цвет кнопки обратно в белый
            }
        }

        const icon = Sprite.from(micro);
        icon.anchor.set(0.5)

        gsap.to(bgMicro.scale, {
            duration: 1,       // Продолжительность анимации в секундах
            x: 1.1,            // Максимальный масштаб по X (увеличение в 1.5 раза)
            y: 1.1,            // Максимальный масштаб по Y (увеличение в 1.5 раза)
            repeat: -1,        // Бесконечный повтор
            yoyo: true,        // Анимация будет возвращаться к исходному масштабу
            ease: "power1.inOut" // Плавное увеличение и уменьшение
        });

        bgMicro.addChild(icon as any);
        return bgMicro
    }

    sendAudioToServer(audioBlob: Blob){
        const formData = new FormData();
        formData.append('audio', audioBlob, 'recording.wav');

        AxiosClient.post('/game/mess_gpt', formData, {headers: {"Content-Type": "multipart/form-data"}})
            .then(response => console.log(1))
            .then(data => {
                console.log('Successfully uploaded:', data);
            })
            .catch(error => {
                console.error('Error uploading audio:', error);
            });
    };

    // async convertWebmToMp3(webmBlob: Blob): Promise<Blob> {
    //     const ffmpeg = new FFmpeg();
    //     await ffmpeg.load();
    //
    //     const inputName = 'input.webm';
    //     const outputName = 'output.wav';
    //     const arrayBuffer = await webmBlob.arrayBuffer();
    //     const uint8Array = new Uint8Array(arrayBuffer);
    //     ffmpeg.writeFile(outputName, uint8Array);
    //     await ffmpeg.exec(['-i', inputName, outputName]);
    //     const outputData: any = await ffmpeg.readFile(outputName);
    //     const outputBlob = new Blob([outputData], { type: 'audio/wav; codecs=0' });
    //     return outputBlob;
    // }

    async createWavBlob(
        audioBlob: Blob,
        numChannels: number = 1,
        sampleRate: number = 16000,
        bitsPerSample: number = 16
    ): Promise<Blob> {
        const arrayBuffer = await audioBlob.arrayBuffer();
        const audioContext = new OfflineAudioContext(1, arrayBuffer.byteLength, 16000); // 1 канал, 16kHz
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        // Создаем новый буфер для PCM данных
        const pcmData = audioBuffer.getChannelData(0); // Получаем данные только первого канала
        const wavBuffer = this.encodeWav(pcmData, 1, audioBuffer.sampleRate, 16);

        // Создаем WAV Blob
        return new Blob([wavBuffer], { type: 'audio/wav' });
    }

    encodeWav(
        samples: Float32Array,
        numChannels: number,
        sampleRate: number,
        bitsPerSample: number
    ): ArrayBuffer {
        const blockAlign = numChannels * (bitsPerSample / 8);
        const byteRate = sampleRate * blockAlign;
        const buffer = new ArrayBuffer(44 + samples.length * 2);
        const view = new DataView(buffer);

        // WAV Header
        this.writeString(view, 0, 'RIFF');
        view.setUint32(4, 36 + samples.length * 2, true); // Размер файла
        this.writeString(view, 8, 'WAVE');
        this.writeString(view, 12, 'fmt ');
        view.setUint32(16, 16, true);
        view.setUint16(20, 1, true); // Формат PCM
        view.setUint16(22, numChannels, true);
        view.setUint32(24, sampleRate, true);
        view.setUint32(28, byteRate, true);
        view.setUint16(32, blockAlign, true);
        view.setUint16(34, bitsPerSample, true);
        this.writeString(view, 36, 'data');
        view.setUint32(40, samples.length * 2, true);

        // Записываем PCM данные
        for (let i = 0; i < samples.length; i++) {
            const s = Math.max(-1, Math.min(1, samples[i]));
            view.setInt16(44 + i * 2, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
        }

        return buffer;
    }
    downloadBlob(blob: Blob, filename: string): void {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url); // Очистка URL для освобождения памяти
    }

    writeString(view: DataView, offset: number, string: string): void {
        for (let i = 0; i < string.length; i++) {
            view.setUint8(offset + i, string.charCodeAt(i));
        }
    }
}

export default Game