import Modal from "../UI/Modal";
import {Application, ColorSource, Graphics, ICanvas, Sprite, Text} from "pixi.js";
import {Viewport} from "pixi-viewport";
import Button from "../UI/Button";
import Input from "../UI/Input";
import {CheckBox, List, RadioGroup, Slider} from "@pixi/ui";
import crossIcon from "../../assetsGame/icons/cross.png";
import {ISettingsTexture, TypeTexture} from "../models/ITextures";

class SettingsTextures extends Modal {

    #name: string;
    #textTrue: string
    #textFalse: string
    #textNoActive: string
    error = false
    _app: Application<ICanvas>
    _viewport: Viewport
    _input: Input;
    _button: Button;
    #size: number
    #type: TypeTexture
    constructor(app: Application<ICanvas>, viewport: Viewport, data: ISettingsTexture, settings: (data: ISettingsTexture) => void) {
        super(app);
        this.alpha = 0
        this._app = app
        this._viewport = viewport;
        this.x = app.view.width / 2 - this.width / 2;
        this.y = app.view.height / 2 - this.height / 2;

        this.#name = data.name
        this.#size = data.size * 100
        this.#type = data.type
        this.#textTrue = data.textTrue
        this.#textFalse = data.textFalse
        this.#textNoActive = data.textNoActive

        const border = new Graphics();
        border.lineStyle(4, 0x000000, 1); // толщина 4px, цвет черный, непрозрачность 1
        border.drawRoundedRect(0, 0, this.width, this.height, 10);

        this._button = new Button('Подтвердить', 300, 50);
        this._button.view.x = this.width / 2 - this._button.view.width / 2;
        this._button.view.y = this.height - this._button.view.height - 10
        this._button.view.onpointerdown = () => {
            settings({size: this.#size / 100, name: this.#name, type: this.#type, textNoActive: this.#textNoActive, textFalse: this.#textFalse, textTrue: this.#textTrue})
        }
        this._input = new Input('Название объекта', 500, 50);
        this._input.x = this.width / 2 - this._input.width / 2;
        this._input.y = 20;
        this._input.value = this.#name;
        this._input.onChange.connect((text) => {
            this.#name = text
        })

        const cross = Sprite.from(crossIcon);
        cross.x = this.width - cross.width - 5; // Отступ от левого края
        cross.y = 5 // Отступ от верхнего края
        cross.cursor = 'pointer'
        cross.onpointerdown = () => {
            this.destroy()
        }

        const textElem = new Text('Размер', {fontSize: 30, align: 'center'});
        textElem.anchor.set(0.5)
        textElem.x = this.width / 2;
        textElem.y = 320;

        const radioSelect = this.radioBtnSelect(data.type)

        const textTrue = new Input('Текст верного ответа', 400, 50);
        textTrue.x = radioSelect.width + 100;
        textTrue.y = 80;
        textTrue.value = this.#textTrue;
        textTrue.onChange.connect((text) => {
            this.#textTrue = text
        })

        const textFalse = new Input('Текст не верного ответа', 400, 50);
        textFalse.x = radioSelect.width + 100;
        textFalse.y = 160;
        textFalse.value = this.#textFalse;
        textFalse.onChange.connect((text) => {
            this.#textFalse = text
        })

        const textNoActive = new Input('Текст не активного ответа', 400, 50);
        textNoActive.x = radioSelect.width + 100;
        textNoActive.y = 240;
        textNoActive.value = this.#textNoActive;
        textNoActive.onChange.connect((text) => {
            this.#textNoActive = text
        })

        const sliderSize = this.sliderSize()
        this.addChild(cross as any)
        this.addChild(textTrue as any)
        this.addChild(textFalse as any)
        this.addChild(textNoActive as any)
        this.addChild(textElem as any)
        this.addChild(sliderSize as any)
        this.addChild(radioSelect.innerView as any)
        this.addChild(border as any);
        this.addChild(this._input as any);
        this.addChild(this._button.view as any)
    }

    drawRadio({ color, fillColor, width, height, radius, padding }: {
        color: ColorSource;
        fillColor?: ColorSource;
        width: number;
        height: number;
        radius: number;
        padding: number;
    })
    {
        const graphics = new Graphics();

        const isCircle = width === height && radius >= width / 2;

        if (isCircle) {
            graphics.beginFill(color);
            graphics.drawCircle(width / 2, width / 2, width / 2);
            graphics.endFill();
        } else {
            graphics.beginFill(color);
            graphics.drawRoundedRect(0, 0, width, height, radius);
            graphics.endFill();
        }

        if (fillColor !== undefined) {
            const center = width / 2;

            if (isCircle) {
                graphics.beginFill(fillColor);
                graphics.drawCircle(center, center, center - padding);
                graphics.endFill();
            } else {
                graphics.beginFill(fillColor);
                graphics.drawRoundedRect(
                    padding,
                    padding,
                    width - padding * 2,
                    height - padding * 2,
                    radius
                );
                graphics.endFill();
            }
        }

        return graphics;
    }

    radioBtnSelect(selected: number) {
        const args = {
            text: 'Radio',
            textColor: '#FFFFFF',
            bgColor: '#EFEFFF',
            fillColor: '#AEA6D2',
            width: 50,
            height: 50,
            padding: 5,
            radius: 25,
            amount: 3,
        };
        const checkTrue = new CheckBox({
            text: `Верный`,
            style: {
                unchecked: this.drawRadio({
                    color: args.bgColor,
                    width: args.width,
                    height: args.height,
                    radius: args.radius,
                    padding: args.padding,
                }),
                checked: this.drawRadio({
                    color: args.bgColor,
                    fillColor: args.fillColor,
                    width: args.width,
                    height: args.height,
                    radius: args.radius,
                    padding: args.padding,
                }),
                text: {
                    fontSize: 22,
                    fill: '#000000',
                },
            },
        });

        const checkFalse = new CheckBox({
            text: `Не верный`,
            style: {
                unchecked: this.drawRadio({
                    color: args.bgColor,
                    width: args.width,
                    height: args.height,
                    radius: args.radius,
                    padding: args.padding,
                }),
                checked: this.drawRadio({
                    color: args.bgColor,
                    fillColor: args.fillColor,
                    width: args.width,
                    height: args.height,
                    radius: args.radius,
                    padding: args.padding,
                }),
                text: {
                    fontSize: 22,
                    fill: '#000000',
                },
            },
        });

        const checkNoActive = new CheckBox({
            text: `Не активный`,
            style: {
                unchecked: this.drawRadio({
                    color: args.bgColor,
                    width: args.width,
                    height: args.height,
                    radius: args.radius,
                    padding: args.padding,
                }),
                checked: this.drawRadio({
                    color: args.bgColor,
                    fillColor: args.fillColor,
                    width: args.width,
                    height: args.height,
                    radius: args.radius,
                    padding: args.padding,
                }),
                text: {
                    fontSize: 22,
                    fill: '#000000',
                },
            },
        });

        const radioGroup = new RadioGroup({
            selectedItem: selected,
            items: [checkTrue, checkFalse, checkNoActive],
            type: 'vertical',
            elementsMargin: 30,
        });

        radioGroup.innerView.x = 90
        radioGroup.innerView.y = 80
        radioGroup.selected = this.#type
        radioGroup.onChange.connect((id, val) => {
            if (id === 0)
                this.#type = TypeTexture.TRUE
            if (id === 1)
                this.#type = TypeTexture.FALSE
            if (id === 2)
                this.#type = TypeTexture.NO_ACTIVE
        })

        return radioGroup
    }

    sliderSize() {
        const args2 = {
            meshColor: '#a5e34d',
            fillColor: '#00b1dd',
            borderColor: '#FFFFFF',
            backgroundColor: '#fe6048',
            fontColor: '#FFFFFF',
            min: 0,
            max: 200,
            step: 1,
            value: this.#size,
            width: 450,
            height: 35,
            radius: 25,
            fontSize: 20,
            border: 3,
            handleBorder: 3,
            showValue: true,
        };

        const list = new List({ type: 'vertical', elementsMargin: 10 });
        const bg = new Graphics()
            .beginFill(args2.borderColor)
            .drawRoundedRect(0, 0, args2.width, args2.height, args2.radius)
            .endFill()
            .beginFill(args2.backgroundColor)
            .drawRoundedRect(args2.border, args2.border, args2.width - (args2.border * 2), args2.height - (args2.border * 2), args2.radius)
            .endFill()

        const fill = new Graphics()
            .beginFill(args2.borderColor)
            .drawRoundedRect(0, 0, args2.width, args2.height, args2.radius)
            .endFill()
            .beginFill(args2.fillColor)
            .drawRoundedRect(args2.border, args2.border, args2.width - (args2.border * 2), args2.height - (args2.border * 2), args2.radius)
            .endFill()

        const slider = new Graphics()
            .beginFill(args2.borderColor)
            .drawCircle(0, 0, 20 + args2.handleBorder)
            .endFill()
            .beginFill(args2.meshColor)
            .drawCircle(0, 0, 20)
            .endFill()

        // Component usage
        const singleSlider = new Slider({
            bg,
            fill,
            slider,
            min: args2.min,
            max: args2.max,
            step: args2.step,
            value: args2.value,
            valueTextStyle: {
                fill: args2.fontColor,
                fontSize: args2.fontSize
            },
            showValue: args2.showValue
        });
        singleSlider.onUpdate.connect((value) => {
            this.#size = value
        });


        singleSlider.value = args2.value;
        list.addChild(singleSlider as any);
        list.x = this.width / 2 - list.width / 2
        list.y = 350

        return list
    }
}

export default SettingsTextures;