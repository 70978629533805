import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {useAppDispatch} from "../../../../hooks/redux";
import {Col, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {setIsSend} from "../../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../../layouts/UI/SubmitWithLoading";
import TestImageHTML from "../portfolioPage/TestImageHTML";
import AboutMePortfolio from "../portfolioPage/AboutMePortfolio";
import ReactCrop, {Crop, PixelCrop} from "react-image-crop";
import userDummayImage from "../../../../assets/images/users/user-dummy-img.jpg";

interface IEditAboutMe {
    portfolio: any,
    setPortfolio(value: any): any,
    selectPagePortfolio: any,
    selectEditPagePortfolio: boolean,
    setSelectEditPagePortfolio(value: boolean): any
}

const EditAboutMePortfolio: FC<IEditAboutMe> = ({ portfolio, setPortfolio, selectEditPagePortfolio, setSelectEditPagePortfolio, selectPagePortfolio }) => {
    const dispatch = useAppDispatch();
    const [editPagePortfolio, setEditPagePortfolio] = useState<any>();
    const [content, setContent] = useState(JSON.parse(selectPagePortfolio?.content)); //для вывода контента из бд
    const [photoListBase64, setPhotoListBase64] = useState<any[]>(selectPagePortfolio.photoBase64); // для отображения в диве
    const [photoPathList, setPhotoPathList] = useState<any[]>(JSON.parse(selectPagePortfolio.photo.replace(/'/g, '"')));
    const [filePhoto, setFilePhoto] = useState(photoPathList?.[0] ? process.env.REACT_APP_API_BASE_URL + photoPathList?.[0] : userDummayImage);
    const [fileForm, setFileForm] = useState<Blob | null>(); //картинка загруженная блоб
    const [isEditPortfolioPage, setIsEditPortfolioPage] = useState<boolean>(false);
    const [crop, setCrop] = useState<Crop>();
    const [cropPath, setCropPath] = useState<any>();
    const [croppedImage, setCroppedImage] = useState<string | null>(null);
    const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);

    useEffect(() => {
        if (isEditPortfolioPage) {
            document.getElementById('handleCombineImagesAboutMe')?.click();
            setIsEditPortfolioPage(false);
        }
    }, [isEditPortfolioPage]);


    console.log(selectPagePortfolio.photoBase64)

    const onCropComplete = useCallback((newCrop: PixelCrop) => {
        if (imageRef && newCrop.width && newCrop.height) {
            const canvas = document.createElement('canvas');
            const scaleX = imageRef.naturalWidth / imageRef.width;
            const scaleY = imageRef.naturalHeight / imageRef.height;
            canvas.width = newCrop.width;
            canvas.height = newCrop.height;
            const ctx = canvas.getContext('2d');

            if (ctx) {
                ctx.drawImage(
                    imageRef,
                    newCrop.x * scaleX,
                    newCrop.y * scaleY,
                    newCrop.width * scaleX,
                    newCrop.height * scaleY,
                    0,
                    0,
                    newCrop.width,
                    newCrop.height
                );
            }
            canvas.toBlob((blob) => {
                if (blob) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setPhotoListBase64((prevArrList: any) => {
                            const newArrList = [...prevArrList];
                            newArrList[0] = reader.result;
                            return newArrList;
                        });
                        setCropPath(reader.result as string);
                    };
                    reader.readAsDataURL(blob);

                    setFileForm(blob);
                }
            });
        }
    }, [imageRef]);

    const onImageLoad = useCallback(
        (event: React.SyntheticEvent<HTMLImageElement>) => {
            const img = event.currentTarget as HTMLImageElement;
            img.crossOrigin = "anonymous";
            setImageRef(img);

            const initialCrop = {
                unit: '%',
                width: 50,
                aspect: 4 / 5,
            };

            const canvas = document.createElement('canvas');
            const scaleX = img.naturalWidth / img.width;
            const scaleY = img.naturalHeight / img.height;
            const x = (img.width - img.width * initialCrop.width / 100) / 2;
            const y = (img.height - img.height * initialCrop.width / (100 * initialCrop.aspect)) / 2;
            canvas.width = img.naturalWidth * initialCrop.width / 100;
            canvas.height = canvas.width / initialCrop.aspect;

            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.drawImage(
                    img,
                    x * scaleX,
                    y * scaleY,
                    canvas.width,
                    canvas.height,
                    0,
                    0,
                    canvas.width,
                    canvas.height
                );
            }

            canvas.toBlob((blob: Blob |null) => {
                if (blob) {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        setPhotoListBase64((prevArrList: any) => {
                            const newArrList = [...prevArrList]; 
                            newArrList[0] = reader.result;
                            return newArrList;
                        });
                        setCropPath(reader.result as string);
                    };
                    reader.readAsDataURL(blob);

                    setFileForm(blob);
                }
            });
        },
        []
    );
    console.log(fileForm)
    console.log(filePhoto)
    console.log(photoListBase64)
    console.log(photoPathList)
    console.log(portfolio?.photo_page)

    return (
        <>
            <Modal id="createProjectModal" isOpen={selectEditPagePortfolio}
                   toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                   modalClassName="zoomIn" tabIndex={-1} centered >
                <ModalHeader toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Изменение страницы «{selectPagePortfolio?.type_page_portfolio.name}»
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={
                            {
                                name: content.name ? content.name : `${portfolio?.last_name} ${portfolio?.name} ${portfolio?.middle_name}`,
                                date: content.date,
                                zodiac: content.zodiac,
                                address: content.address,
                            }
                        }
                        validationSchema={
                            Yup.object({

                            })
                        }
                        onSubmit={(values) => {
                            // dispatch(setIsSend(true));
                            setEditPagePortfolio({
                                name: values.name,
                                date: values.date,
                                zodiac: values.zodiac,
                                address: values.address,
                                uid: portfolio?.child_uid,
                                selectPage: selectPagePortfolio,
                                id_pattern: portfolio?.pattern_page_portfolio_id,
                                photo: fileForm,
                                photo_download_base64: photoListBase64,
                                path_photo: photoPathList,
                                path_photo_page: portfolio?.photo_page,
                            });
                            setIsEditPortfolioPage(true);
                        }}
                    >
                        {
                            ({ errors, touched, setFieldValue }) => (
                                <Form>

                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">
                                                ФИО:
                                                <Field name="name" className={`form-control ${touched.name && errors.name ? 'is-invalid' : ''}`} />
                                            </label>
                                        </Col>
                                    </Row>

                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">
                                                Дата рождения:
                                                <Field name="date" className={`form-control ${touched.date && errors.date ? 'is-invalid' : ''}`} />
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">
                                                Знак зодиак:
                                                <Field name="zodiac" className={`form-control ${touched.zodiac && errors.zodiac ? 'is-invalid' : ''}`} />
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">
                                                Место жительства:
                                                <Field name="address" className={`form-control ${touched.address && errors.address ? 'is-invalid' : ''}`} />
                                            </label>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <label className="w-100">
                                                Фото (.jpg, .png):
                                                <Input
                                                    name="photo"
                                                    type="file"
                                                    className={`form-control}`}
                                                    accept=".png, .jpg"
                                                    onChange={(e: any) => {
                                                        const file = e.target.files[0];

                                                        if (file) {
                                                            const reader = new FileReader();
                                                            reader.onloadend = () => {
                                                                setPhotoListBase64((prevArrList: any) => {
                                                                    const newArrList : any = [...prevArrList];
                                                                    newArrList[0] = reader.result;
                                                                    return newArrList;
                                                                });
                                                                setFilePhoto(reader.result as string);
                                                            };
                                                            reader.readAsDataURL(file);
                                                        }
                                                    }}
                                                />
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="my-2">
                                        {fileForm && (
                                            <Col>
                                                <label className="w-100">Выберите область для обрезки:
                                                    <ReactCrop
                                                        crop={crop}
                                                        aspect={4 / 5}
                                                        onChange={(newCrop: any) => {
                                                            setCrop(newCrop)
                                                        }}
                                                        onComplete={onCropComplete}
                                                    >
                                                        <img src={filePhoto} alt="Crop" onLoad={onImageLoad} />
                                                    </ReactCrop>
                                                </label>
                                            </Col>
                                        )}
                                        <Col>
                                            <label className={photoListBase64?.[0] ? "w-100" : "w-50"}>Загруженное фото:
                                                {(!croppedImage || filePhoto) && (
                                                    <img
                                                        src={cropPath ? cropPath : filePhoto}
                                                        className="user-profile-image w-100 h-auto"
                                                        alt="cropped"
                                                    />
                                                )}
                                            </label>
                                        </Col>
                                    </Row>
                                    <div className="hstack gap-2">
                                        <SubmitWithLoading text={'Готово'} />
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
            </Modal>
            { editPagePortfolio && <AboutMePortfolio
                dispatch={(value) => dispatch(value)}
                page={editPagePortfolio}
                setPortfolio={(value) => setPortfolio(value)}
                setSelectEditPagePortfolio={(value) => setSelectEditPagePortfolio(value)}
            />}
        </>
    );
};

export default EditAboutMePortfolio;
