import React, {FC, useRef, useState} from 'react';
import {Col, Input, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {setIsSend} from "../../../../store/reducers/widgets/WidgetsSlice";
import SubmitWithLoading from "../../../layouts/UI/SubmitWithLoading";
import {useAppDispatch} from "../../../../hooks/redux";
import userDummayImage from "../../../../assets/images/users/user-dummy-img.jpg";

interface IEditAdvanceOrCreation {
    portfolio: any,
    setPortfolio(value: any): any,
    selectPagePortfolio: any,
    selectEditPagePortfolio: boolean,
    setSelectEditPagePortfolio(value: boolean): any
}

const EditAdvanceOrCreation: FC<IEditAdvanceOrCreation> = ({ portfolio, setPortfolio, selectEditPagePortfolio, setSelectEditPagePortfolio, selectPagePortfolio }) => {
    const dispatch = useAppDispatch();
    const [editPagePortfolio, setEditPagePortfolio] = useState<any>();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [maxCharsPerLine, setMaxCharsPerLine] = useState<number>(0);
    const [filePhoto, setFilePhoto] = useState(portfolio?.photo != '' ? process.env.REACT_APP_API_BASE_URL+ portfolio?.photo : userDummayImage)
    const [fileForm64, setFileForm64] = useState<string | ArrayBuffer | null>('');
    const [fileForm, setFileForm] = useState<string | Blob>('');


    return (
        <>
            <Modal id="createProjectModal" isOpen={selectEditPagePortfolio}
                   toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                   modalClassName="zoomIn" tabIndex={-1} centered >
                <ModalHeader toggle={() => setSelectEditPagePortfolio(!selectEditPagePortfolio)}
                             className="p-3 bg-soft-primary"
                             id="createProjectModalLabel">
                    Изменение страницы «{selectPagePortfolio?.type_page_portfolio.name}»
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize
                        initialValues={
                            {
                                name: portfolio?.name,
                                photo: fileForm,
                            }
                        }
                        validationSchema={ 
                            Yup.object({
                                name: Yup.string(),
                            })
                        }
                        onSubmit={async (values) => {
                            dispatch(setIsSend(true));
                            await setEditPagePortfolio({
                                name: values.name,
                                photo: fileForm,
                                uid: portfolio?.child_uid,
                                selectPage: selectPagePortfolio,
                                id_pattern: portfolio?.pattern_page_portfolio_id,
                            });

                            document.getElementById('handleCombineImagesBigDescriptionPage')?.click();
                        }}
                    >
                        {
                            ({ errors, touched, setFieldValue }) => (
                                <Form>
                                    <Row className="mb-2">
                                        <Col>
                                            <label className="w-100">
                                                Имя:
                                                <Field name="name" className={`form-control ${touched.name && errors.name ? 'is-invalid' : ''}`} />
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label className="w-100">
                                                Фото (.jpg, .png):
                                                <Input
                                                    name="photo"
                                                    type="file"
                                                    className={`form-control ${touched.photo && errors.photo ? 'is-invalid' : ''}`}
                                                    accept=".png, .jpg"
                                                    onChange={(e: any) => {
                                                        const file = e.target.files[0];

                                                        if (file) {
                                                            setFileForm(file);
                                                            const reader = new FileReader();
                                                            reader.onloadend = () => {
                                                                setFileForm64(reader.result);
                                                                setFieldValue('photo', reader.result);
                                                                setFilePhoto(reader.result as string);
                                                            };
                                                            reader.readAsDataURL(file);
                                                        }
                                                    }}
                                                />
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className="my-2">
                                        <Col>
                                            <img
                                                src={filePhoto}
                                                className="avatar-xl img-thumbnail user-profile-image"
                                                alt="user-profile"/>
                                        </Col>
                                    </Row>
                                    <div className="hstack gap-2">
                                        <SubmitWithLoading text={'Готово'} />
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );
};

export default EditAdvanceOrCreation;
