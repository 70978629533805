import Modal from "../UI/Modal";
import {Application, Graphics, ICanvas, Sprite, Texture, Text} from "pixi.js";
import gsap from "gsap";
import {Viewport} from "pixi-viewport";
import iconCreate from '../../assetsGame/icons/create.png'
import iconCreateHover from '../../assetsGame/icons/createHover.png'
import iconSave from '../../assetsGame/icons/save.png'
import iconSaveHover from '../../assetsGame/icons/saveHover.png'
import GameNameModal from "./GameNameModal";
import AxiosClient from "../../api/AxiosClient";
import {IGame} from "../models/IGame";


class Menu extends Modal {
    _app: Application<ICanvas>;
    _viewport: Viewport;
    _game: IGame;
    gameNameModal: GameNameModal | undefined
    constructor(app: Application<ICanvas>, viewport: Viewport, game: IGame) {
        super(app, 300, 50);
        this._game = game
        this._viewport = viewport;
        this._app = app;
        this.alpha = 1
        this.x = 10;
        this.y = 0;

        const saveBtn = Sprite.from(iconSave)
        const defaultTextureSaveBtn = Texture.from(iconSave)
        const hoverTextureSaveBtn = Texture.from(iconSaveHover)
        saveBtn.y = this.height / 2 - saveBtn.height / 2
        saveBtn.x = 10
        saveBtn.cursor = 'pointer'
        saveBtn.interactive = true;
        saveBtn.onmouseover = () => {
            saveBtn.texture = hoverTextureSaveBtn
        }
        saveBtn.onmouseout = () => {
            saveBtn.texture = defaultTextureSaveBtn
        }
        saveBtn.onpointerdown = () => {
            const gameData = {
                name: this.gameNameModal?.name,
                shots: this.gameNameModal?.sceneGame?.shots.map(el => ({
                    personages: el.personageResult.map(el => ({...el, sprite: null, path: ''})),
                    textures: el.texturesResult.map(el => ({...el, sprite: null, path: ''})),
                    name: el.name,
                    soundBg: {...el.soundBg, base64: ''},
                    startText: el.startText,
                    bg: {...el.bg, base64: ''},
                    id: el.id
                }))
            }
            const loadingBackground = new Graphics();
            loadingBackground.beginFill(0x000000, 0.8); // Черный цвет с прозрачностью 80%
            loadingBackground.drawRoundedRect(0, 0, app.screen.width, app.screen.height, 0);
            loadingBackground.endFill();
            app.stage.addChild(loadingBackground as any);

            // Текст "Сохранение.."
            const loadingText = new Text("Сохранение..", {
                fontFamily: "Arial",
                fontSize: 36,
                fill: 0xffffff,
                align: "center"
            });
            loadingText.anchor.set(0.5);
            loadingText.position.set(app.screen.width / 2, app.screen.height / 2);
            loadingBackground.addChild(loadingText as any);
            AxiosClient.post(`/game/update/${game.id}`, {game: gameData})
                .then((r) => {
                    loadingBackground.destroy();
                    window.location.href = '/games/teacher'
                })
        }
        this.addChild(saveBtn as any)
        const gameNameModal = new GameNameModal(this._app, this._viewport, this._game)
        this.gameNameModal = gameNameModal
        this._viewport.addChild(gameNameModal as any);
        gsap.to(gameNameModal, {alpha: 1, duration: 0.5});
    }
}

export default Menu;