import Modal from "../UI/Modal";
import {Application, DisplayObject, Graphics, ICanvas, Sprite, Text} from "pixi.js";
import iconDots from '../../assetsGame/icons/dots.png'
import {Viewport} from "pixi-viewport";
import SettingsShot from "./SettingsShot";
import gsap from "gsap";
import ScrollBox from "../UI/ScrollBox";
import crossIcon from '../../assetsGame/icons/cross.png'
import SettingsTextures from "./SettingsTextures";
import {ITexture, ITextures, TypeTexture} from "../models/ITextures";
import {IPersonage, IPersonages} from "../models/IPersonages";
import {ISound} from "../models/ISound";
import {IBackgroundImage} from "../models/IBackgroundImage";
import micro from "../../assetsGame/icons/microphone.png";

class Block extends Modal {
    _app: Application<ICanvas>
    _viewport: Viewport
    #index = 0
    #name
    #isChatGPT = false;
    #microphoneIcon: Graphics | null = null;
    #bgBlock: IBackgroundImage = {
        id: 0,
        name: '',
        path: '',
        base64: ''
    }
    _scrollBox: ScrollBox
    #dots: Sprite
    #text: Text
    #personage: IPersonage = {
        id: 0,
        path: '',
        base64: '',
        name: ''
    }
    #textures: ITexture = {
        id: 0,
        path: '',
        base64: '',
        name: ''
    }
    #texturesResult: ITextures[] = []
    #personageResult: IPersonages[] = []
    #soundBg: ISound = {
        id: 0,
        name: '',
        path: '',
        base64: ''
    }
    #startText = ''
    #copy: Sprite
    copyBlock = () => {};

    constructor(app: Application<ICanvas>, viewport: Viewport, scrollBox: ScrollBox, name: string) {
        super(app, 500, 50);
        this._app = app
        this._viewport = viewport
        this._scrollBox = scrollBox
        this.#name = name
        this.x = app.view.width / 2 - 150
        this.y = app.view.height / 2 - 25

        const text = new Text(name, {fontSize: 25})
        text.name = 'nameShot'
        text.y = 10
        text.x = this.width / 2 - text.width / 2

        const info = Sprite.from(iconDots)
        info.y = 10
        info.x = 10
        info.onpointerdown = () => {
            this.openSettings()
        }

        const copy = Sprite.from(iconDots)
        copy.y = 10
        copy.x = this.width
        copy.onpointerdown = () => {
            this.copyBlock()
        }
        this.#copy = copy
        this.addChild(copy as any)
        this.#dots = info
        this.#text = text

        this.addChild(info as any)
        this.addChild(text as any)
    }

    openSettings() {
        this.children?.forEach((el, index) => {
            if (index > 0)
                el.visible = true
        })
        this.#dots.visible = false
        this.#text.visible = false
        this.#copy.visible = false
        if(this.#microphoneIcon){
            this.#microphoneIcon.visible = true
        }
        const sceneGame: DisplayObject | undefined = this._app.stage.children.find(el => el.name === 'sceneGame')
        const settings = new SettingsShot(this._app, this, () => {
            this.#texturesResult.forEach((el) => {
                el.sprite.visible = false
            })
            this.#personageResult.forEach((el) => {
                el.sprite.visible = false
            })
            const view = new Graphics()
            view.beginFill(0xFFFFFF)
            view.drawRoundedRect(0, 0, 500, 50, 10)
            this.setNewBg = view
            // this.children[0] = view as any
            const items = this._scrollBox.items.map(el => el as any)
            items.splice(this.#index, 0, this as any);
            this._scrollBox.removeItems();
            this._scrollBox.addItems(items)
            this._scrollBox.visible = true
            this.children?.forEach((el, index) => {
                if (index > 0)
                    el.visible = false
            })
            this.#dots.visible = true
            this.#text.visible = true
            this.#copy.visible = true
            if(this.#microphoneIcon){
                this.#microphoneIcon.visible = false
            }
            if (sceneGame) {
                const menuShot = (sceneGame?.children as DisplayObject[]).find((el) => el.name === 'menuShot')
                if (menuShot) {
                    menuShot.visible = true;
                }
            }
        })
        if (sceneGame) {
            const menuShot = (sceneGame?.children as DisplayObject[]).find((el) => el.name === 'menuShot')
            if (menuShot) {
                menuShot.visible = false;
            }
        }

        this._viewport.addChildAt(settings as any, 0)
        gsap.to(settings, {alpha: 1, duration: 0.5})
        this._scrollBox.visible = false
        this.y = 60;
        this.x = 10
        if (this.#bgBlock.base64){
            const view = new Graphics()
            view.beginFill(0xFFFFFF)
            view.drawRoundedRect(0, 0, this._app.view.width - 340, this._app.view.height - 70, 10)
            const sprite = Sprite.from(this.#bgBlock.base64)
            sprite.width = this._app.view.width
            sprite.height = this._app.view.height
            const mask = new Graphics();
            mask.beginFill(0xFFFFFF);
            mask.drawRoundedRect(10, 60, this._app.view.width - 340, this._app.view.height - 70, 10)
            mask.endFill();
            sprite.mask = mask;
            view.addChild(sprite as any)
            this.setNewBg = view
            this.children[0] = view as any
            this._viewport.addChild(this as any)
        } else {
            const view = new Graphics()
            view.beginFill(0xFFFFFF)
            view.drawRoundedRect(0, 0, this._app.view.width - 340, this._app.view.height - 70, 10)
            this.setNewBg = view
            this.children[0] = view as any
            this._viewport.addChild(this as any)
        }
    }

    get isChatGPT(){
        return this.#isChatGPT;
    }
    set isChatGPT(value) {
        this.#isChatGPT = value
        if (value && !this.#microphoneIcon) {
            let mediaRecorder: MediaRecorder | null = null;
            let chunks: Blob[] = [];
            this.#microphoneIcon = new Graphics();
            this.#microphoneIcon.beginFill(0xFFFFFF); // Белый цвет
            this.#microphoneIcon.drawCircle(0, 0, 32);
            this.#microphoneIcon.endFill();
            this.#microphoneIcon.x = this.width - this.#microphoneIcon.width / 2 - 5
            this.#microphoneIcon.y = this.height - this.#microphoneIcon.height / 2 - 5
            this.#microphoneIcon.onpointerdown = async () => {
                try {
                    if (!mediaRecorder || mediaRecorder.state === 'inactive') {
                        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                        mediaRecorder = new MediaRecorder(stream);

                        mediaRecorder.ondataavailable = (e) => {
                            chunks.push(e.data);
                        };

                        mediaRecorder.onstop = () => {
                            const audioBlob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' });

                            chunks = [];
                        };

                        mediaRecorder.start();
                        console.log('Recording started');
                        this.#microphoneIcon!.tint = 0xff0000;  // Изменение цвета кнопки на красный, когда запись активна
                    }
                } catch (error) {
                    console.error('Error accessing microphone:', error);
                }
            }

            this.#microphoneIcon.onpointerup = () => {
                if (mediaRecorder && mediaRecorder.state === 'recording') {
                    mediaRecorder.stop();
                    console.log('Recording stopped');
                    this.#microphoneIcon!.tint = 0xffffff;  // Вернуть цвет кнопки обратно в белый
                }
            }

            this.#microphoneIcon.onpointerupoutside = () => {
                if (mediaRecorder && mediaRecorder.state === 'recording') {
                    mediaRecorder.stop();
                    console.log('Recording stopped');
                    this.#microphoneIcon!.tint = 0xffffff;  // Вернуть цвет кнопки обратно в белый
                }
            }

            const icon = Sprite.from(micro);
            icon.anchor.set(0.5)

            gsap.to(this.#microphoneIcon.scale, {
                duration: 1,       // Продолжительность анимации в секундах
                x: 1.1,            // Максимальный масштаб по X (увеличение в 1.5 раза)
                y: 1.1,            // Максимальный масштаб по Y (увеличение в 1.5 раза)
                repeat: -1,        // Бесконечный повтор
                yoyo: true,        // Анимация будет возвращаться к исходному масштабу
                ease: "power1.inOut" // Плавное увеличение и уменьшение
            });

            this.#microphoneIcon.addChild(icon as any);
            this.addChild(this.#microphoneIcon as any);
        }

        if(!value && this.#microphoneIcon) {
            this.removeChild(this.#microphoneIcon as any)
            this.#microphoneIcon.destroy()
            this.#microphoneIcon = null
        }
    }

    get name() {
        return this.#name;
    }
    set index(value: number) {
        this.#index = value
    }

    set name(value) {
        this.#name = value;
        const text: Text | undefined = this.children.find(el => el.name === 'nameShot') as Text
        if (text) {
            text.text = value
        }
    }

    get bg() {
        return this.#bgBlock;
    }

    set bg(value) {
        this.#bgBlock = value;
        const view = new Graphics()
        view.beginFill(0xFFFFFF)
        view.drawRoundedRect(0, 0, this._app.view.width - 340, this._app.view.height - 70, 10)
        const sprite = Sprite.from(value.base64)
        sprite.width = this._app.view.width - 340
        sprite.height = this._app.view.height - 70
        const mask = new Graphics();
        mask.beginFill(0xFFFFFF);
        mask.drawRoundedRect(10, 60, this._app.view.width - 340, this._app.view.height - 70, 10)
        mask.endFill();
        sprite.mask = mask;
        view.addChild(sprite as any)
        this.setNewBg = view
        this.children[0] = view as any
    }

    get personage() {
        return this.#personage;
    }

    get personageResult() {
        return this.#personageResult
    }

    set personageResult(value) {
        this.#personageResult = value

        value.forEach((value) => {
            const onePercentX = +((this._app.view.width - 340) / 100).toFixed(2)
            const onePercentY = +((this._app.view.height - 70) / 100).toFixed(2)
            let name = value.name
            const sprite = Sprite.from(value.path);
            let p: null | Sprite = null;
            sprite.x = onePercentX * value.percentX;
            sprite.y = onePercentY * value.percentY;
            sprite.anchor.set(0.5);

            const cross = Sprite.from(crossIcon);
            cross.width = cross.width * 2
            cross.height = cross.height * 2
            cross.x = sprite.width / 2 - cross.width / 2; // Отступ от левого края
            cross.y = -sprite.height / 2 - cross.height / 2; // Отступ от верхнего края
            cross.visible = false
            cross.cursor = 'pointer'

            sprite.addChild(cross as any);
            sprite.scale.set(value.size);

            cross.onpointerdown = () => {
                sprite.destroy()
                const index = this.#personageResult.findIndex(item => item.name === name);
                if (index !== -1) {
                    this.#personageResult.splice(index, 1);
                }
            }
            sprite.onpointerover = () => {
                cross.visible = true
            }
            sprite.onpointerout = () => {
                cross.visible = false
            }

            sprite.onpointerdown = () => {
                sprite.alpha = 0.5;
                p = sprite
                sprite.onpointermove = (event) => {
                    if (p) {
                        sprite.cursor = 'move'
                        sprite.parent.toLocal(event.data.getLocalPosition(this as any), this as any, sprite.position);
                        this.limitPosition(sprite)
                    }
                }
            }
            sprite.onpointerup = () => {
                if (p) {
                    sprite.off('pointermove', (event) => sprite.parent.toLocal(event.global, this as any, sprite.position));
                    sprite.alpha = 1;
                    const index = this.#personageResult.findIndex(item => item.name === name);
                    if (index !== -1) {
                        this.#personageResult[index].x = sprite.x;
                        this.#personageResult[index].y = sprite.y;
                        this.#personageResult[index].percentX = +(sprite.x / onePercentX).toFixed(2);
                        this.#personageResult[index].percentY = +(sprite.y / onePercentY).toFixed(2);
                    }
                    p = null
                    sprite.cursor = 'default'
                }
            }

            sprite.onpointerupoutside = () => {
                if (p) {
                    sprite.off('pointermove', (event) => sprite.parent.toLocal(event.global, this as any, sprite.position));
                    sprite.alpha = 1;
                    const index = this.#personageResult.findIndex(item => item.name === name);
                    if (index !== -1) {
                        this.#personageResult[index].x = sprite.x;
                        this.#personageResult[index].y = sprite.y;
                        this.#personageResult[index].percentX = +(sprite.x / onePercentX).toFixed(2);
                        this.#personageResult[index].percentY = +(sprite.y / onePercentY).toFixed(2);
                    }
                    p = null
                    sprite.cursor = 'default'
                }
            }
            sprite.visible = false
            this.addChild(sprite as any)
        })
    }

    get texturesResult() {
        return this.#texturesResult
    }

    set texturesResult(value) {
        this.#texturesResult = value

        value.forEach((value) => {
            const onePercentX = +((this._app.view.width - 340) / 100).toFixed(2)
            const onePercentY = +((this._app.view.height - 70) / 100).toFixed(2)
            let name = value.name
            const sprite = Sprite.from(value.path);
            let p: null | Sprite = null;
            sprite.x = onePercentX * value.percentX;
            sprite.y = onePercentY * value.percentY;
            sprite.anchor.set(0.5);

            const cross = Sprite.from(crossIcon);
            cross.width = cross.width * 2
            cross.height = cross.height * 2
            cross.x = sprite.width / 2 - cross.width / 2; // Отступ от левого края
            cross.y = -sprite.height / 2 - cross.height / 2; // Отступ от верхнего края
            cross.visible = false
            cross.cursor = 'pointer'

            const iconDotsSprite = Sprite.from(iconDots);
            iconDotsSprite.width = iconDotsSprite.width * 2
            iconDotsSprite.height = iconDotsSprite.height * 2
            iconDotsSprite.x = -sprite.width / 2 - iconDotsSprite.width / 2; // Отступ от левого края
            iconDotsSprite.y = -sprite.height / 2 - iconDotsSprite.height / 2; // Отступ от верхнего края
            iconDotsSprite.visible = false
            iconDotsSprite.cursor = 'pointer'



            sprite.scale.set(value.size);

            sprite.addChild(cross as any);
            sprite.addChild(iconDotsSprite as any);
            iconDotsSprite.scale.set(1 / sprite.scale.x)
            cross.scale.set(1 / sprite.scale.x)
            cross.onpointerdown = () => {
                sprite.destroy()
                const index = this.#texturesResult.findIndex(item => item.name === name);
                if (index !== -1) {
                    this.#texturesResult.splice(index, 1);
                }
            }
            iconDotsSprite.onpointerdown = () => {
                const index = this.#texturesResult.findIndex(item => item.name === name);
                const data = this.#texturesResult[index]
                const settingsTextures = new SettingsTextures(this._app, this._viewport, data, (data) => {
                    sprite.scale.set(data.size)
                    if (index !== -1) {
                        this.#texturesResult[index].type = data.type;
                        this.#texturesResult[index].name = data.name;
                        this.#texturesResult[index].textTrue = data.textTrue;
                        this.#texturesResult[index].textFalse = data.textFalse;
                        this.#texturesResult[index].textNoActive = data.textNoActive;
                        name = data.name
                        this.#texturesResult[index].size = data.size;
                    }
                    iconDotsSprite.scale.set(1 / sprite.scale.x)
                    cross.scale.set(1 / sprite.scale.x)
                    settingsTextures.destroy()
                })
                this._viewport.addChild(settingsTextures as any);

                gsap.to(settingsTextures, {alpha: 1, duration: 0.3});
            }
            sprite.onpointerover = () => {
                cross.visible = true
                iconDotsSprite.visible = true
            }
            sprite.onpointerout = () => {
                cross.visible = false
                iconDotsSprite.visible = false
            }

            sprite.onpointerdown = () => {
                sprite.alpha = 0.5;
                p = sprite
                sprite.onpointermove = (event) => {
                    if (p) {
                        sprite.cursor = 'move'
                        sprite.parent.toLocal(event.data.getLocalPosition(this as any), this as any, sprite.position);
                        this.limitPosition(sprite)
                    }
                }
            }
            sprite.onpointerup = () => {
                if (p) {
                    sprite.off('pointermove', (event) => sprite.parent.toLocal(event.global, this as any, sprite.position));
                    const index = this.#texturesResult.findIndex(item => item.name === name);
                    if (index !== -1) {
                        this.#texturesResult[index].x = sprite.x;
                        this.#texturesResult[index].y = sprite.y;
                        this.#texturesResult[index].percentX = +(sprite.x / onePercentX).toFixed(2);
                        this.#texturesResult[index].percentY = +(sprite.y / onePercentY).toFixed(2);
                    }
                    sprite.alpha = 1;
                    p = null
                    sprite.cursor = 'default'
                }
            }

            sprite.onpointerupoutside = () => {
                if (p) {
                    sprite.off('pointermove', (event) => sprite.parent.toLocal(event.global, this as any, sprite.position));
                    const index = this.#texturesResult.findIndex(item => item.name === name);
                    if (index !== -1) {
                        this.#texturesResult[index].x = sprite.x;
                        this.#texturesResult[index].y = sprite.y;
                        this.#texturesResult[index].percentX = +(sprite.x / onePercentX).toFixed(2);
                        this.#texturesResult[index].percentY = +(sprite.y / onePercentY).toFixed(2);
                    }
                    sprite.alpha = 1;
                    p = null
                    sprite.cursor = 'default'
                }
            }
            sprite.visible = false
            this.addChild(sprite as any)
        })
    }

    set personage(value) {
        this.#personage = value;
        const onePercentX = +((this._app.view.width - 340) / 100).toFixed(2)
        const onePercentY = +((this._app.view.height - 70) / 100).toFixed(2)
        let name = `Персонаж ${this.#personageResult.length + 1}`
        const sprite = Sprite.from(value.base64);
        let p: null | Sprite = null;
        sprite.x = sprite.width / 2;
        sprite.y = this.height - (sprite.height / 2);
        sprite.anchor.set(0.5);

        this.#personageResult.push({
            x: sprite.width / 2,
            y: this.height - (sprite.height / 2),
            path: value.base64,
            size: 0.4,
            name: name,
            sprite: sprite,
            id: value.id,
            pathOrig: value.path,
            percentY: +((this.height - (sprite.height / 2)) / onePercentY).toFixed(2),
            percentX: +((sprite.width / 2) / onePercentX).toFixed(2)
        })

        const cross = Sprite.from(crossIcon);
        cross.width = cross.width * 2
        cross.height = cross.height * 2
        cross.x = sprite.width / 2 - cross.width / 2; // Отступ от левого края
        cross.y = -sprite.height / 2 - cross.height / 2; // Отступ от верхнего края
        cross.visible = false
        cross.cursor = 'pointer'

        sprite.addChild(cross as any);
        sprite.scale.set(0.4);

        cross.onpointerdown = () => {
            sprite.destroy()
            const index = this.#personageResult.findIndex(item => item.name === name);
            if (index !== -1) {
                this.#personageResult.splice(index, 1);
            }
        }
        sprite.onpointerover = () => {
            cross.visible = true
        }
        sprite.onpointerout = () => {
            cross.visible = false
        }

        sprite.onpointerdown = () => {
            sprite.alpha = 0.5;
            p = sprite
            sprite.onpointermove = (event) => {
                if (p) {
                    sprite.cursor = 'move'
                    sprite.parent.toLocal(event.data.getLocalPosition(this as any), this as any, sprite.position);
                    this.limitPosition(sprite)
                }
            }
        }
        sprite.onpointerup = () => {
            if (p) {
                sprite.off('pointermove', (event) => sprite.parent.toLocal(event.global, this as any, sprite.position));
                sprite.alpha = 1;
                const index = this.#personageResult.findIndex(item => item.name === name);
                if (index !== -1) {
                    this.#personageResult[index].x = sprite.x;
                    this.#personageResult[index].y = sprite.y;
                    this.#personageResult[index].percentX = +(sprite.x / onePercentX).toFixed(2);
                    this.#personageResult[index].percentY = +(sprite.y / onePercentY).toFixed(2);
                }
                p = null
                sprite.cursor = 'default'
            }
        }

        sprite.onpointerupoutside = () => {
            if (p) {
                sprite.off('pointermove', (event) => sprite.parent.toLocal(event.global, this as any, sprite.position));
                sprite.alpha = 1;
                const index = this.#personageResult.findIndex(item => item.name === name);
                if (index !== -1) {
                    this.#personageResult[index].x = sprite.x;
                    this.#personageResult[index].y = sprite.y;
                    this.#personageResult[index].percentX = +(sprite.x / onePercentX).toFixed(2);
                    this.#personageResult[index].percentY = +(sprite.y / onePercentY).toFixed(2);
                }
                p = null
                sprite.cursor = 'default'
            }
        }

        this.addChild(sprite as any)
    }

    get textures() {
        return this.#textures;
    }

    set soundBg(value) {
        this.#soundBg = value
    }

    setOldBg(value: IBackgroundImage) {
        this.#bgBlock = value;
    }

    get soundBg(){
        return this.#soundBg
    }

    set startText(value: string) {
        this.#startText = value
    }

    get startText() {
        return this.#startText
    }

    set textures(value: ITexture) {
        this.#textures = value;
        const onePercentX = +((this._app.view.width - 340) / 100).toFixed(2)
        const onePercentY = +((this._app.view.height - 70) / 100).toFixed(2)
        let name = `Объект ${this.#texturesResult.length + 1}`
        const sprite = Sprite.from(value.base64);
        let p: null | Sprite = null;
        sprite.x = sprite.width / 2;
        sprite.y = this.height - (sprite.height / 2);
        sprite.anchor.set(0.5);

        this.#texturesResult.push({
            x: sprite.width / 2,
            y: this.height - (sprite.height / 2),
            path: value.base64,
            pathOrig: value.path,
            id: value.id,
            size: 0.4,
            name: name,
            type: TypeTexture.TRUE,
            textFalse: '',
            textNoActive: '',
            textTrue: '',
            sprite: sprite,
            percentY: +((this.height - (sprite.height / 2)) / onePercentY).toFixed(2),
            percentX: +((sprite.width / 2) / onePercentX).toFixed(2)
        })

        const cross = Sprite.from(crossIcon);
        cross.width = cross.width * 2
        cross.height = cross.height * 2
        cross.x = sprite.width / 2 - cross.width / 2; // Отступ от левого края
        cross.y = -sprite.height / 2 - cross.height / 2; // Отступ от верхнего края
        cross.visible = false
        cross.cursor = 'pointer'

        const iconDotsSprite = Sprite.from(iconDots);
        iconDotsSprite.width = iconDotsSprite.width * 2
        iconDotsSprite.height = iconDotsSprite.height * 2
        iconDotsSprite.x = -sprite.width / 2 - iconDotsSprite.width / 2; // Отступ от левого края
        iconDotsSprite.y = -sprite.height / 2 - iconDotsSprite.height / 2; // Отступ от верхнего края
        iconDotsSprite.visible = false
        iconDotsSprite.cursor = 'pointer'



        sprite.scale.set(0.4);

        sprite.addChild(cross as any);
        sprite.addChild(iconDotsSprite as any);
        iconDotsSprite.scale.set(1 / sprite.scale.x)
        cross.scale.set(1 / sprite.scale.x)
        cross.onpointerdown = () => {
            sprite.destroy()
            const index = this.#texturesResult.findIndex(item => item.name === name);
            if (index !== -1) {
                this.#texturesResult.splice(index, 1);
            }
        }
        iconDotsSprite.onpointerdown = () => {
            const index = this.#texturesResult.findIndex(item => item.name === name);
            const data = this.#texturesResult[index]
            const settingsTextures = new SettingsTextures(this._app, this._viewport, data, (data) => {
                sprite.scale.set(data.size)
                if (index !== -1) {
                    this.#texturesResult[index].type = data.type;
                    this.#texturesResult[index].name = data.name;
                    this.#texturesResult[index].textTrue = data.textTrue;
                    this.#texturesResult[index].textFalse = data.textFalse;
                    this.#texturesResult[index].textNoActive = data.textNoActive;
                    name = data.name
                    this.#texturesResult[index].size = data.size;
                }
                iconDotsSprite.scale.set(1 / sprite.scale.x)
                cross.scale.set(1 / sprite.scale.x)
                settingsTextures.destroy()
            })
            this._viewport.addChild(settingsTextures as any);

            gsap.to(settingsTextures, {alpha: 1, duration: 0.3});
        }
        sprite.onpointerover = () => {
            cross.visible = true
            iconDotsSprite.visible = true
        }
        sprite.onpointerout = () => {
            cross.visible = false
            iconDotsSprite.visible = false
        }

        sprite.onpointerdown = () => {
            sprite.alpha = 0.5;
            p = sprite
            sprite.onpointermove = (event) => {
                if (p) {
                    sprite.cursor = 'move'
                    sprite.parent.toLocal(event.data.getLocalPosition(this as any), this as any, sprite.position);
                    this.limitPosition(sprite)
                }
            }
        }
        sprite.onpointerup = () => {
            if (p) {
                sprite.off('pointermove', (event) => sprite.parent.toLocal(event.global, this as any, sprite.position));
                const index = this.#texturesResult.findIndex(item => item.name === name);
                if (index !== -1) {
                    this.#texturesResult[index].x = sprite.x;
                    this.#texturesResult[index].y = sprite.y;
                    this.#texturesResult[index].percentX = +(sprite.x / onePercentX).toFixed(2);
                    this.#texturesResult[index].percentY = +(sprite.y / onePercentY).toFixed(2);
                }
                sprite.alpha = 1;
                p = null
                sprite.cursor = 'default'
            }
        }

        sprite.onpointerupoutside = () => {
            if (p) {
                sprite.off('pointermove', (event) => sprite.parent.toLocal(event.global, this as any, sprite.position));
                const index = this.#texturesResult.findIndex(item => item.name === name);
                if (index !== -1) {
                    this.#texturesResult[index].x = sprite.x;
                    this.#texturesResult[index].y = sprite.y;
                    this.#texturesResult[index].percentX = +(sprite.x / onePercentX).toFixed(2);
                    this.#texturesResult[index].percentY = +(sprite.y / onePercentY).toFixed(2);
                }
                sprite.alpha = 1;
                p = null
                sprite.cursor = 'default'
            }
        }
        this.addChild(sprite as any)
    }

    limitPosition(sprite: Sprite) {
        if (sprite.x < sprite.width / 2) {
            sprite.x = sprite.width / 2;
        } else if (sprite.x > (this._app.view.width - 340) - sprite.width / 2) {
            sprite.x = (this._app.view.width - 340) - sprite.width / 2;
        }

        // Ограничиваем позицию по Y
        if (sprite.y < sprite.height / 2) {
            sprite.y = sprite.height / 2;
        } else if (sprite.y > (this._app.view.height - 70) - sprite.height / 2) {
            sprite.y = (this._app.view.height - 70) - sprite.height / 2;
        }
    };
}

export default Block