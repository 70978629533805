import React, {useEffect, useRef} from 'react';
import {useParams} from "react-router-dom";
import {Application} from "pixi.js";
import Game from "../../game/update/Game";
import {Container} from "reactstrap";
import {Helmet} from "react-helmet";

const GameUpdate = () => {
    const {id} = useParams()
    const ref = useRef<any>(null);

    useEffect(() => {
        const app = new Application({
            width: ref.current.offsetWidth,
            height: ref.current.offsetHeight,
            autoDensity: true,
            backgroundColor: 0xEFEFFF,
            eventMode: 'static',
        });
        ref.current.appendChild(app.view);
        if (id)
            new Game(app, Number(id))

        app.start();
        return () => {
            app.destroy(true, true);
        };
    }, [id]);

    return (
        <div className="page-content" style={{height: '100vh'}}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Обновление игры</title>
            </Helmet>
            <div ref={ref} style={{overflow: 'hidden', overflowY: 'hidden', width: '100%', height: '100%'}}></div>
        </div>
    );
};

export default GameUpdate;
